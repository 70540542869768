import { createStore } from "vuex";
import { apolloClient } from "../main.js";
import {
  COURSE_LISTING_QUERY,
  COURSE_DETAILS_QUERY,
  SEARCH_DETAILS_QUERY,
  COURSE_OUTLINE_DETAILS_QUERY,
} from "@/constant/graphql.js";
import createPersistedState from "vuex-persistedstate";
import Cookies from 'js-cookie';

export default createStore({
  state: {
    userName: "Cambridge User",
    userEmail:"Cambridge@cambridge.org",
    courseListing: [
      {
        id: "1",
        courseName: "Principles of Economics",
        topicCounts: "10",
        courseCompletionPercantage: "20.7",
        courseEstimationTime: "3h 31min",
        instituteName: "Mishkin & Perloff",
      },
      {
        id: "2",
        courseName: "Research Methods in Psychology",
        topicCounts: "12",
        courseCompletionPercantage: "40.89",
        courseEstimationTime: "4h 15min",
        instituteName: "APA",
      },
      {
        id: "3",
        courseName:
          "ASU BIO 100 Research Methods in Psychology Principles of Economics",
        topicCounts: "6",
        courseCompletionPercantage: "76.77",
        courseEstimationTime: "2h 45min",
        instituteName: "ASU",
      },
    ],
    courseDetails: {
      courseIntro:
        "<em>The History of the United States </em> provides a Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      courseStatus: "Start",
      courseImg:
        "https://author.cogbooks.com/cogContent/newui/content/course/header.jpg",
    },
    searchDetails: [],
    objID: 1,
    sidebarNavCollapsed: true,
    isSearchBarVisible: false,
    cbjwt: null,
    jwtPayload: {},
    courseOutlineInfo:[]
  },
  getters: {
    getCourseListing: (state) => state.courseListing,
    getCourseDetails: (state) => state.courseDetails,
    getUserName: (state) => state.userName,
    getUserEmail: (state) => state.userEmail,
  },
  mutations: {
    setSearchBarVisible(state, isSearchBarVisible) {
      state.isSearchBarVisible = isSearchBarVisible;
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    setUserEmail(state, userEmail) {
      state.userEmail = userEmail;
    },
    setCourseListing(state, courseListing) {
      state.courseListing = courseListing;
    },
    setCourseDetails(state, courseDetails) {
      state.courseDetails = courseDetails;
    },
    setSearchDetails(state, searchDetails) {
      state.searchDetails = searchDetails;
    },
    setcourseid(state, objID) {
      state.objID = objID;
    },
    setCBjwt(state, cbjwt) {
      state.cbjwt = cbjwt;
    },
    setjwtPayload(state, jwtPayload) {
      state.jwtPayload = jwtPayload;
    },
    toggleSidebarNav(state) {
      state.sidebarNavCollapsed = !state.sidebarNavCollapsed;
    },
    setCourseOutlineInfo(state, courseOutlineInfo){
      state.courseOutlineInfo = courseOutlineInfo;
    },
  },
  actions: {
    fetchCourseListing({ commit }, { userID }) {
      console.log("inside courseListing 338", userID);
      return apolloClient
        .query({
          query: COURSE_LISTING_QUERY,
          variables: { uid: userID },
        })
        .then((response) => {
          console.log("Response is" + response.data.courseListing);
          if (response.data.courseListing.length == 0) {
            console.log(
              "No courseListing found for the given id",
              courseListing
            );
          } else if (response.data.errors != null) {
            console.log(
              "error in courseListing response ",
              response.data.errors
            );
          } else {
            commit("setCourseListing", response.data.courseListing);
          }
        })
        .catch((err) => {
          console.log(
            " Error during apollo-client query in Fetch Learner dashboard data" +
              err
          );
        });
    },

    fetchCourseDetails({ commit }, { objID,userID }) {
      console.log("inside courseListing 338")
      return apolloClient.query({
        query: COURSE_DETAILS_QUERY,
        variables: { uid: userID,objID:objID}
      }).then(response => {
        console.log("Response is" + (response.data.courseDetails))
        if (response.data.courseDetails.length == 0) {
          console.log("No courseListing found for the given id", courseListing)
          // Implement UX for no data found
        }
        else if (response.data.errors != null) {
          console.log("error in courseListing response ", response.data.errors)
          // Implement UX for back-end-error
        }
        else {
          commit('setCourseDetails', response.data.courseDetails);
        }
      })
        .catch(err => {
          console.log(" Error during apollo-client query in fetchGradesInfo     " + err)
          // Implement UX for back-end-error
        })
    },

    fetchSearchDetails({ commit }, { objID }) {
      console.log("inside searchdetails 338")
      return apolloClient.query({
        query: SEARCH_DETAILS_QUERY,
        variables: { objID:objID}
      }).then(response => {
        console.log("Response is" + (response.data.courseSearchInfo))
        if (response.data.courseSearchInfo.length == 0) {
          console.log("No searchDetails found for the given id", searchDetails)
          // Implement UX for no data found
        }
        else if (response.data.errors != null) {
          console.log("error in searchDetails response ", response.data.errors)
          // Implement UX for back-end-error
        }
        else {
          var name = []
          for(var i=0;i<response.data.courseSearchInfo.length;i++){
            name.push(response.data.courseSearchInfo[i].Name);
          }
          var al = []
          al["NAME"] = name;
          console.log(al);
          commit('setSearchDetails', []);
          commit('setSearchDetails', al);
        }
      })
        .catch(err => {
          console.log(" Error during apollo-client query in fetchGradesInfo     " + err)
          // Implement UX for back-end-error
        })
    },
    fetchCourseOutlineInfo({ commit }, {objID, uid }) {
      console.log("inside courseoulinw,,,,, 338")
      return apolloClient.query({
        query: COURSE_OUTLINE_DETAILS_QUERY,
        variables: {objID:objID,uid:uid}
      }).then(response => {
        console.log("Response is" + (response.data.courseOutlineDetails))
        if (response.data.courseOutlineDetails.length == 0) {
          console.log("No courseListing found for the given id", courseOutlineDetails)
          // Implement UX for no data found
        }
        else if (response.data.errors != null) {
          console.log("error in courseListing response ", response.data.errors)
          // Implement UX for back-end-error
        }
        else {
          commit('setCourseOutlineInfo', response.data.courseOutlineDetails);
        }
      })
        .catch(err => {
          console.log(" Error during apollo-client query in fetchGradesInfo     " + err)
          // Implement UX for back-end-error
        })
    },
    toggleSidebarNav({ commit }) {
      commit("toggleSidebarNav");
    },
    userNameAction({commit}){
      const userName = Cookies.get('username');
      commit('setUserName', userName);
    },
    userEmailAction({commit}){
      const userEmail = Cookies.get('userEmail');
      commit('setUserEmail', userEmail);
    },
    cbjwtToken({ commit }, cbjwt) {
      commit("setCBjwt", cbjwt);
    },
    cbjwtTokenPayload({ commit }, jwtPayload) {
      commit("setjwtPayload", jwtPayload);
    },
  },
  modules: {},
  plugins:[createPersistedState()]
});
