<!-- <template>
  <div>
    <h4 id="main-content" tabindex="1">Home Page content goes here..</h4>
    <p tabindex="1">Padding can be added as per VD for different pages</p>
  </div>
</template>

<script>
  export default{
    components:{
      
    },
    mounted(){
      let titleTag = document.getElementsByTagName("title")[0];
      titleTag.innerHTML = "Your Homepage | Dashboard";
    }
  }
</script>

<style scoped>
.main-content:focus{
  border: 1px solid black;
}

</style> -->
<template>
  <div class="home-page-wrapper">
  <div class="homepage-coming-soon">
    <h2>An exciting, personalized dashboard is</h2>
    <div class="d-flex align-items-center justify-content-center gap-3">
      <h1>Coming Soon</h1>
      <img height="40" width="40" src='../assets/img/rocket.png'/>
    </div>
    <p>Soon you'll be able to track your course progress, view <br> performance analytics, and more.</p>
  </div>
  <div class="homepage-goto-courses">
    <p>While you wait, why not check out your current courses and <br> keep up with you learning journey?</p>
      <router-link tabindex="-1" :to="{ name: 'courses', query: { cbjwt: cupajwt } }" @click="goToCourses">
            <span>Go to courses</span>
      </router-link>
  </div>
</div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: 'DashboardComingSoon',
  data() {
    return {
      cupajwt:'',
    };
  },
  mounted(){
      let titleTag = document.getElementsByTagName("title")[0];
      titleTag.innerHTML = "Your Homepage | Dashboard";
      let cbjwt = Cookies.get("cbjwt");
      console.log(cbjwt);
      this.cupajwt = cbjwt;
  },
  methods: {
    goToCourses() {
      this.$router.push({ name: "courses", query:{cbjwt:`${this.cupajwt}`} });
    }
  }
}
</script>

<style scoped>
.homepage-coming-soon {
  background-color: #E0F2FE; /* Light blue background */
  text-align: center;
  padding: 50px;
  border-radius: 6px;
}

.homepage-goto-courses{
  text-align: center;
  padding: 50px;
}

.homepage-coming-soon h2 {
  color: #333; /* Dark text for heading */
  font-size: 24px;
  font-weight: 600;
}

.homepage-coming-soon h1 {
  color: #333; /* Dark text for heading */
  font-size: 64px;
  font-weight: bolder;
}

.homepage-coming-soon p {
  color: #666; /* Lighter text for paragraph */
}

.homepage-goto-courses p {
  color: #666; /* Lighter text for paragraph */
}

.homepage-goto-courses a {
  background-color: #0056b3; /* Blue background for button */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 6px;
  margin-top: 32px;
  width: 172px;
  display: inline-block;
  text-decoration: none;
}
.homepage-goto-courses span {
  color: white;
  font: normal normal 500 16px/24px "Source Sans 3";
}

.home-page-wrapper {
  background-color: white;
  padding: 16px 16px 16px 16px;
  box-shadow: 0px 3px 6px rgb(214, 214, 214);
  border-radius: 6px;
  margin: 16px;
  height: 97%;
}
</style>