<template>
  <div
    class="sidebar-nav"
    ref="sidebar"
    :class="!sidebarNavCollapsed ? 'sidebar-nav-expanded' : ''"
  >
    <nav>
      <ul>
        <li
          class="homebutton"
          tabindex="1"
          aria-label="Home Button, press enter to go to home page"
          @keyup.enter="navigateToHome"
        >
          <router-link tabindex="-1" :to="{ name: 'home', query: { cbjwt: cupajwt } }" @click="navigateToHome">
            <img src="../assets/img/home-icon-black.svg" class="d-block" />
            <span>Home</span>
          </router-link>
        </li>
        <li
          class="coursesbutton"
          tabindex="1"
          aria-label="Courses button, you are currently viewing courses page."
          @keyup.enter="navigateToCourses"
        >
          <router-link tabindex="-1" :to="{ name: 'courses', query: { cbjwt: cupajwt } }" @click="navigateToCourses">
            <img src="../assets/img/courses-icon-black.svg" class="d-block" />
            <span>Courses</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div
      class="sidebar-nav-toggle-arrow"
      :data-tooltip-text="sidebarNavCollapsed ? 'Expand' : 'Collapse'"
      :class="!sidebarNavCollapsed ? 'toggle-arrow-collpase' : ''"
      @click="toggleSidebarNav()"
    >
      <img src="../assets/img/right-arrow-blue.svg" alt="right arrow icon" />
    </div>
  </div>
  <div
    class="empty-placeholder"
    :class="!sidebarNavCollapsed ? 'empty-placeholder-expanded' : ''"
  ></div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      cupajwt:'',
      isMobile: false
    };
  },
  computed: {
    ...mapState({
      sidebarNavCollapsed: "sidebarNavCollapsed",
    }),
  },
  methods: {
    ...mapActions(["toggleSidebarNav"]),
    navigateToHome() {
      this.$router.push({ name: "home", query:{cbjwt:`${this.cupajwt}`} });
    },
    navigateToCourses() {
      this.$router.push({ name: "courses", query:{cbjwt:`${this.cupajwt}`} });
    },
    
  },
  mounted(){
    this.isMobile = window.innerWidth <= 1023;
    let cbjwt = Cookies.get("cbjwt");
    console.log(cbjwt);
    this.cupajwt = cbjwt;
  },

};
</script>

<style lang="scss">
.sidebar-nav {
  position: fixed;
  top: 80px;
  height: calc(100% - 80px);
  left: 0px;
  z-index: 9;
  background-color: white;
  width: 64px;
  box-shadow: 0px 3px 6px rgb(214, 214, 214);
  animation: sidebar-collapse 0.7s ease-in;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;

    li {
      a {
        display: flex;
        align-items: center;
        padding: 16px 0px;
        color: rgb(33, 33, 33);
        text-decoration: none;
        font: normal normal 600 16px/22px "SourceSansPro-Regular";
        position: relative;
      }

      a:hover {
        background-color: rgb(247, 247, 247);
      }
      .router-link-active {
        color: rgb(84, 101, 248);
        img {
          filter: invert(32%) sepia(63%) saturate(2755%) hue-rotate(226deg)
            brightness(101%) contrast(94%);
        }
      }
      .router-link-active:hover {
        background-color: rgb(229, 237, 248);
      }
      .router-link-active::before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        height: 100%;
        width: 4px;
        background-color: rgb(84, 101, 248);
      }

      a img {
        margin-right: 16px;
        margin-left: 24px;
      }

      span {
        opacity: 0;
        transition: 1.2s;
      }
    }
  }

  nav {
    width: 100%;
    overflow-x: hidden;
  }
}

.sidebar-nav-expanded {
  width: 200px;
  animation: sidebar-expand 0.7s ease-in;

  ul li span {
    opacity: 1;
    transition: 2s;
  }
}

@keyframes sidebar-expand {
  0% {
    width: 64px;
  }
  25% {
    width: 54px;
  }
  100% {
    width: 200px;
  }
}

@keyframes sidebar-collapse {
  0% {
    width: 200px;
  }
  25% {
    width: 210px;
  }
  100% {
    width: 64px;
  }
}

@media only screen and (max-width: 1023px) {
  .sidebar-nav {
    width: 200px !important;
    transform: translateX(-100%);
    position: fixed;
    left: 0px;
    top: 56px;
    transition: 0.3s;
    height: 100%;
    z-index: 99;
    background-color: white;
    animation: none !important;

    ul li span {
      opacity: 1 !important;
    }
  }

  .sidebar-nav-expanded {
    animation: none !important;
    transform: translateX(0%);
  }

  .sidebar-nav-toggle-icon {
    padding: 0px;
  }
}

.empty-placeholder {
  width: 64px;
  animation: sidebar-collapse 0.7s ease-in;
}

.empty-placeholder-expanded {
  width: 200px;
  animation: sidebar-expand 0.7s ease-in;
}

.sidebar-nav-toggle-arrow {
  position: absolute;
  top: 13px;
  right: 0%;
  width: 28px;
  height: 28px;
  background: white;
  box-shadow: 2px 2px 2px #00000029;
  border-radius: 0px 15px 15px 0px;
  transform: translateX(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: hidden;
}

.sidebar-nav-toggle-arrow.toggle-arrow-collpase img {
  transform: scale(-1, -1);
}

.sidebar-nav:hover .sidebar-nav-toggle-arrow {
  visibility: visible;
}
/* Tooltip text */
.sidebar-nav-toggle-arrow::before {
  content: attr(data-tooltip-text); /* Get tooltip text from title attribute */
  position: absolute;
  left: 120%;
  background-color: #424242;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease;
  pointer-events: none; /* Ensure it doesn't interfere with clicks */
  font: normal normal normal 14px/18px 'Source Sans 3';
}
.sidebar-nav-toggle-arrow::after {
     content: "";
     position: absolute;
     right: -30%;
     transform: translate(0, -50%);
     top: 50% !important;
     border: 6px solid #E0E0E0;
     border-color: transparent #424242 transparent transparent;
     right: -6px;
     opacity: 0;
     transition: opacity 0.3s ease;
     pointer-events: none;
     /* -webkit-filter: drop-shadow(-2px 0 2px rgba(0, 0, 0, 0.16)); */
   }

/* Show tooltip on hover */
.sidebar-nav-toggle-arrow:hover::before {
  opacity: 1;
}
.sidebar-nav-toggle-arrow:hover::after {
  opacity: 1;
}
</style>
