<template>
    <button tabindex="1" @click="skipToMainContent" @keypress.enter="skipToMainContent" class="skip-to-main-content-link">Skip to main content</button>
</template>

<script>

export default {
  name: 'skipToMainContent',
  props: {
    mainContentId: {
      type: String,
      required: true
    },
  },
  methods:{
    skipToMainContent(){
    var headerHeight = document.getElementById('header-div').offsetHeight;
	
    const mainContent = document.getElementById(this.mainContentId);
	  mainContent.focus();
	  //Scroll to the div
	  window.scrollTo({
		    top: mainContent.offsetTop - headerHeight,
		    behavior: 'smooth'
		  });
    }
  },
}

</script>
<style>
.skip-to-main-content-link {
  position: fixed;
  left: 22%;
  z-index: 9999999;
  padding: 8px;
  background-color: white;
  color: #5465f8;
  opacity: 0;
  top: 3%;
  border: 2px solid #5465f8;
  border-radius: 4px;
  font: normal normal 600 16px/22px "Open Sans";   
}
.skip-to-main-content-link:focus {
  left: 22%;
  opacity: 1;
  color: #5465f8;
  text-decoration: none;
  outline: none !important;
}
.skip-to-main-content-link:hover{
	text-decoration: none;
	color: #5465f8 !important;
}

</style>
