<template>
  <div class="outlineIntro" tabindex="1">
    This section displays all the assignments created by your instructors and
    assigned to you in the Learning Management System (LMS)
  </div>
  <div v-if="loading" class="skeleton-loader">
    <CourseOutLineSkelton />
  </div>
  <div v-else class="card">
    <ul class="list-group list-group-flush">
      <li
        ref="topicCard"
        class="list-group-item d-flex topicCardsContent align-items-center justify-content-center"
        v-for="(topic, index) in this.courseOutlineInfo"
        :key="topic.secID"
        :class="{
          'disabled-item': isLocked(topic, index),
          'no-arrow-padding': topic.subSectionData.length < 1,
        }"
        tabindex="1"
        @keydown.enter="toggleSubtopics(topic.secID)"
      >
        <div class="topicDetails d-flex align-items-center justify-center">
          <div class="mainLeftDiv align-items-center d-flex gap-3">
            <img
              v-if="topic.subSectionData.length > 1"
              class="ArrowClosed"
              src="../../assets/img/Arrowclosed.svg"
              :class="{ 'caret-rotated': this.showSubtopics === topic.secID }"
              @click="toggleSubtopics(topic.secID)"
              @keydown.enter="toggleSubtopics(topic.secID)"
              height="18"
              width="18"
              tabindex="1"
              aria-label="Button collapsed, Press enter to view list of subtopics."
            />
            <img
              v-if="topic.color === '3'"
              class="statusImg"
              src="../../assets/img/Icon Not Understood Primary.svg"
            />
            <img
              v-else-if="topic.color === '1'"
              class="statusImg"
              src="../../assets/img/Icon in Progress.svg"
            />
            <img
              v-else-if="topic.color === '2'"
              class="statusImg"
              src="../../assets/img/Icon Understood-Primary.svg"
            />
            <img
              class="statusImg"
              v-else-if="
                index !== 0 &&
                topic.color === '0' &&
                topic.sectionNavigationCriteriaSequence === 'DEFINEDSEQUENCE'
              "
              src="../../assets/img/Lock Icon.svg"
            />
            <img v-else src="../../assets/img/IconNot ViewedYetPrimary.svg" />
            <div
              class="topicNames d-flex gap-3 align-items-center"
              tabindex="1"
              :aria-label="`topic ${index + 1} ${getStatusText(
                topic.color
              )} ${decode(
                topic.name
              )}Link, Press enter to go to the topic details page.`"
              @click="redirectToTopicPage(topic.secID)"
              @keydown.enter="redirectToTopicPage(topic.secID)"
            >
              <div class="topicNameClass" :title="decode(topic.name)">
                <span>{{ topic.secOrder }}</span
                >.&nbsp;{{ decode(topic.name) }}
              </div>
              <img
                height="15"
                width="26"
                src="../../assets/img/SubTopicblueRrrow.svg"
              />
            </div>
          </div>

          <div
            class="rightSideContent d-flex gap-4"
            tabindex="1"
            :aria-label="`${formatSecStatus(
              topic.secStatus
            )} subtopics completed, Estimated Total time for topic completion: ${addHoursandMin(
              topic
            )}`"
          >
            <!-- <span class="">{{ topic.dueDate }}</span> | -->
            <div class="subtopcisCount itemsss pb-1 pr-2">
              <div class="d-flex justify-content-center">
                {{ topic.secStatus }} &nbsp;
                <span v-if="topic.subSectionData.length === 0">activities</span>
                <span v-else="">sub-topics</span>
              </div>
              <div
                :aria-label="`Course progress:${getCompletionPercentage(
                  topic
                )}% of the course is completed`"
                class="card-progress-div"
              >
                <div class="card-progress">
                  <div
                    class="card-progress-bar"
                    :style="getProgressBarStyle(topic.secStatus)"
                  ></div>
                </div>
              </div>
            </div>
            <div class="itemsss leftline11"></div>
            <div class="estimatedTime itemsss d-flex align-items-center gap-2">
              <img src="../../assets/img/clockIconForRecentSearches.svg" />
              <span>{{ addHoursandMinTime(topic.estTime) }}</span>
            </div>
          </div>
        </div>
        <ul
          v-if="this.showSubtopics === topic.secID"
          class="subtopic-list subTopicDiv relative"
          ref="subTopicList"
        >
          <div class="verticalLeftBarForSubtopic"></div>
          <div
            v-show="topic.subSectionData.length > 0"
            class="sub-topic"
            tabindex="1"
          >
            Sub Topics
          </div>
          <li
            v-for="(subtopic, index) in topic.subSectionData"
            :key="index"
            class="d-flex absolute subtopicsList"
            tabindex="1"
            @keydown.enter="redirectToTopicPage(subtopic.subSecID)"
            :aria-label="`Sub topic ${index + 1} ${getStatusText(
              subtopic.subSecColor
            )} ${decode(
              subtopic.subSecName
            )} , Estimated time for sub topic completion: ${addHoursandMinTime(
              subtopic.subSecEst
            )},
Link, Press enter to view subtopic details.
`"
          >
            <div class="leftContent d-flex gap-3">
              <img
                v-if="subtopic.subSecColor === '3'"
                height="18"
                width="18"
                src="../../assets/img/Icon Not Understood Primary.svg"
              />
              <img
                v-else-if="subtopic.subSecColor === '2'"
                height="18"
                width="18"
                src="../../assets/img/Icon Understood-Primary.svg"
              />
              <img
                v-else-if="subtopic.subSecColor === '1'"
                height="18"
                width="18"
                src="../../assets/img/Icon in Progress.svg"
              />
              <img
                v-else
                height="18"
                width="18"
                src="../../assets/img/IconNot ViewedYetPrimary.svg"
              />
              <div
                class="subtopicTitle d-flex align-items-center"
                @click="redirectToTopicPage(subtopic.subSecID)"
                @keydown.enter="redirectToTopicPage(subtopic.subSecID)"
              >
                <div
                  class="subtopicsNamesClass"
                  :title="decode(subtopic.subSecName)"
                >
                  <span> {{ subtopic.subSecOrder }}</span>
                  <span> &nbsp;{{ decode(subtopic.subSecName) }}</span>
                </div>
                <img
                  height="12"
                  width="26"
                  src="../../assets/img/SubTopicblueRrrow.svg"
                />
              </div>
            </div>
            <div
              class="rightContent d-flex align-items-center gap-2"
            >
              <div class="rightContentItems d-flex gap-2 align-items-center">
                <img src="../../assets/img/clockIconForRecentSearches.svg" />
                {{ addHoursandMinTime(subtopic.subSecEst) }}
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { CB_END_POINT } from "../../config/config";
import CourseOutLineSkelton from "../skeltons/CourseOutLineSkelton.vue";
import { ref } from "vue";
import he from "he";
export default {
  name: "CourseOutline",
  components: {
    CourseOutLineSkelton,
  },
  setup() {
    return {};
  },
  data() {
    return {
      highlightedCardId: null,
      secID: 0,
      isActive: false,
      showSubtopics: false,
      loading: true,
      full_Name: "",
      user_email: "",
    };
  },
  methods: {
    ...mapActions([
      "fetchCourseOutlineInfo",
      "fetchCourseListing",
      "fetchCourseDetails",
      "fetchSearchDetails",
    ]),
    toggleSubtopics(secid) {
      this.showSubtopics = this.showSubtopics === secid ? null : secid;
    },
    isSecIDMatching(array, secIDToMatch) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].secID === secIDToMatch) {
          return true;
        }
      }
      return false;
    },
    decode(value) {
      return he.decode(value);
    },
    redirectToTopicPage(sectionId) {
      const courseId = this.$route.params.id;
      window.location.href =
        CB_END_POINT +
        "/handler?action=sectionbranding&COURSEID=" +
        courseId +
        "&SECTIONID=" +
        sectionId;
    },
    isLocked(topic, index) {
      return (
        index !== 0 &&
        topic.color === "0" &&
        topic.sectionNavigationCriteriaSequence === "DEFINEDSEQUENCE"
      );
    },
    focusOnSecID(secID) {
      this.$nextTick(() => {
        if (this.$refs.topicCard) {
          const target = this.$refs.topicCard.find(
            (item) => item.secID === secID
          );
          if (target) {
            target.scrollIntoView({ behavior: "smooth" });
            target.focus();
          }
        }
      });
    },
    getCompletionPercentage(topic) {
      if (topic.subSectionData.length === 0) return 0;
      const completedSubtopics = topic.subSectionData.filter(
        (subtopic) => subtopic.subSecColor === 2
      ).length;
      return Math.round(
        (completedSubtopics / topic.subSectionData.length) * 100
      );
    },
    addHoursandMin(course) {
      var courseTime = course.estTime;
      const [hoursStr, minutesStr] = courseTime.split(" ");
      const hours = parseInt(hoursStr);
      const minutes = parseInt(minutesStr);
      const outputTime = `${hours} hours ${minutes} minutes`;
      console.log(outputTime);
      return outputTime;
    },
    addHoursandMinTime(course) {
      var courseTime = course.trim();
      console.log(courseTime);
      let hours = 0;
      let minutes = 0;

      if (courseTime.includes("h")) {
        const [hoursStr, minutesStr] = courseTime.split(" ");
        hours = parseInt(hoursStr) || 0;
        minutes = parseInt(minutesStr) || 0;
      } else {
        minutes = parseInt(courseTime) || 0;
      }
      const totalMinutes = hours * 60 + minutes;
      hours = Math.floor(totalMinutes / 60);
      minutes = totalMinutes % 60;

      let outputTime;
      if (hours === 0) {
        outputTime = `${minutes}min`;
      } else {
        outputTime = `${hours}h ${minutes}min`;
      }
      console.log(outputTime);
      return outputTime;
    },
    getProgressBarStyle(topicProps) {
        const [completedTopics, totalTopics] = topicProps
          .split("/")
          .map(Number);
        const completionProgress = (completedTopics / totalTopics) * 100;
        const progressPercentage = `${completionProgress}%`;
        const remainingPercentage = `${100 - completionProgress}%`;
        return {
          backgroundColor: '#00bdb6',
          width: progressPercentage,
        };
    },
    formatSecStatus(secStatus) {
      if (!secStatus) {
        return "";
      }
      return secStatus.replace("/", " of ");
    },
    getStatusText(statusString) {
      const statusNumber = parseInt(statusString, 10);

      if (isNaN(statusNumber)) {
        return "Invalid input";
      }
      switch (statusNumber) {
        case 1:
          return "In progress";
        case 2:
          return "Understood";
        default:
          return "Not started";
      }
    },
  },
  computed: {
    ...mapState({
      // 'courseOutlineInfo' will be accessible in this component as this.courseOutlineInfo
      courseOutlineInfo: (state) => state.courseOutlineInfo,
    }),
  },
  mounted() {
    var cbjwt = getParameterByName("cbjwt");
    console.log(" cbjwt ------------- " + cbjwt);
    var payload = cbjwt.split(".")[1];
    console.log(" payload ----------- " + payload);
    var decodePayLoad = window.atob(payload);
    console.log(" decodePayLoad ---------- " + decodePayLoad);
    var payloadJSON = JSON.parse(decodePayLoad);
    console.log(" payloadJSON ---------- " + payloadJSON);
    var classId = payloadJSON.course;
    var uid = payloadJSON.uid;
    console.log("class id --------------- " + classId);
    console.log("user id ---------------- " + uid);
    let objID = this.$route.params.id;
    this.secID = getParameterByName("SECID");
    this.user_email = getParameterByName("EMAIL");
    console.log("7777   " + objID);
    console.log("7777 ----  " + this.secID);
    this.full_Name = payloadJSON.full_name;
    console.log(this.user_email, this.full_Name);

    this.fetchCourseOutlineInfo({
      uid: uid,
      objID: objID,
    }).then(() => {
      (this.loading = false),
        (this.showSubtopics = this.secID),
        this.focusOnSecID(this.secID);
    });
    this.fetchSearchDetails({
      objID: objID,
    });
    this.fetchCourseListing({ userID: uid }).then(() => {
      this.loading = false;
    });
    this.fetchCourseDetails({
      objID: objID,
      userID: uid,
    }).then(() => {
      this.loading = false;
    });
    console.log(this.courseOutlineInfo);
    // this.adjustSubTopicHeights();
  },
};
</script>

<style lang="scss" scoped>
@keyframes progressBarAnimation {
  from {
    width: 0%;
  }
  to {
    width: var(--progress-width);
  }
}
.rightContentItems {
  width: 100px;
}
.leftline11 {
  height: 100%;
  width: 1px;
  background-color: #e9e9e9;
}
.itemsss:first-child,
.itemsss:last-child {
  width: 105px;
}
.itemsss:nth-child(2) {
  width: 1px;
  height: 35px;
}
.outlineIntro {
  font: normal normal normal 16px/24px "Source Sans 3";
  color: #424242;
  padding-block: 24px;
}
.list-group-item {
  min-height: 64px;
  max-height: auto;
  align-items: center;
}
.topicCardsContent {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}
.ArrowClosed:hover {
  cursor: pointer;
}
.topicDetails {
  width: 100%;
  padding: 16px;
}
.mainLeftDiv {
  width: 50%;
}
.topicNames {
  font: normal normal 600 18px/24px "Source Sans 3";
  color: #212121;
}
.topicNameClass {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.subtopicsNamesClass {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.topicNames:hover {
  text-decoration: underline;
  cursor: pointer;
}
.rightSideContent {
  width: 50%;
  justify-content: end;
}
.rightSideContent {
  font: normal normal normal 16px/20px "Source Sans 3";
  color: #757575;
}
.bi-caret-right-fill {
  color: #212121;
}
.caret-rotated {
  transform: rotate(90deg);
}
.subtopic-list {
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding-left: 60px;
}
.sub-topic {
  font: normal normal 600 20px/28px "Source Sans 3";
  color: #757575;
  padding: 16px;
}
.leftLine {
  width: 1px;
  height: 100%;
}
.subtopicsList {
  border-top: 1px solid #e9e9e9;
  padding: 16px 16px 16px 0;
}
.subtopicTitle {
  font: normal normal normal 18px/23px "Source Sans 3";
  color: #424242;
}
.subtopicTitle:hover {
  text-decoration: underline;
  cursor: pointer;
}
.leftContent {
  width: 50%;
}
.rightContent {
  font: normal normal normal 16px/24px "Source Sans 3";
  color: #757575;
  width: 50%;
  justify-content: end;
}
.card-progress-div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1 1 0;
}
.card-progress {
  height: 4px;
  background-color: #e9e9e9;
  border-radius: 5px;
  overflow: hidden;
  flex: 4;
}
.card-progress-bar {
  height: 100%;
  // background-color: #E9E9E9;
  border-radius: 10px;
  // animation: progressBarAnimation 2s ease-in-out forwards;
}
.disabled-item {
  pointer-events: none;
  opacity: 0.5;
}
.no-arrow-padding .topicDetails {
  padding-left: 50px; /* Adjust as needed */
}
.focused-item {
  background-color: #f0f0f0; // Example highlight color
}
.verticalLeftBarForSubtopic {
  border-left: 1px solid #e9e9e9;
  height: calc(100% - 100px);
  position: absolute;
  top: 70px;
  left: 45px;
}
.subtopicsList:before {
  content: "";
  position: relative;
  border-top: 1px solid #e9e9e9;
  width: 30px;
  top: 10px;
  left: -15px;
}
// .statusImg{
//   height: 18px !important;
//   width: 18px !important;
// }
@media only screen and (max-width: 768px) {
  .topicDetails {
    flex-direction: column;
    gap: 16px;
  }
  .mainLeftDiv {
    width: 100%;
  }
  .rightSideContent {
    width: 100%;
    justify-content: flex-start;
  }
  .rightSideContent {
    justify-content: space-between;
    padding-left: 72px;
  }
  .verticalLeftBarForSubtopic {
    left: 24px;
    top: 110px;
    height: calc(100% - 140px);
  }
  .subtopicsList:before {
    left: -18px;
  }
  .subtopic-list {
    padding-left: 42px;
  }
  .topicNames {
    width: 80vw;
  }
  .mainLeftDiv{
    width: 85vw;
    gap: 0.5rem;
  }
  .subtopicsNamesClass{
    max-width: 140px;
  }
  .topicNameClass{
    max-width: 250px;
  }
}
</style>
