import { createApp, provide, h } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createPinia } from 'pinia';
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/main.css';
import { HttpLink } from 'apollo-link-http';
import Cookies from 'js-cookie';
import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client/core";
import { useRoute } from "vue-router";
import { gqendpoint } from "./config/config";
export {apolloClient}
import $ from 'jquery';
window.$ = window.jQuery = $;

const uri = gqendpoint;
const httpLink =new HttpLink({
    uri: uri,
})
const cache = new InMemoryCache();
const authMiddleware = new ApolloLink((operation, forward) => {
    let cbjwt= sessionStorage.getItem("cbjwt")
    console.log("cbjwt from sessionStorage  is "+cbjwt)
    operation.setContext({
      headers: {
        ...Headers,
        authorization: "Bearer " +cbjwt
      }
    });
    return forward(operation);
  })
const apolloClient = new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache,
    connectToDevTools: true,
})
console.log(apolloClient);
const app = createApp({
    setup(){
        provide(DefaultApolloClient, apolloClient)
        },
    render: ()=> h(App),
});

app.use(store);
app.use(createPinia());
app.use(router);
app.mount("#app");
