<template>
  <skipToMainContent :main-content="'main-content'" />
  <header class="float-start w-100">
    <div class="header-div d-flex align-items-center justify-content-between" id="header-div">
      <div class="d-flex align-items-center">
        <div
          tabindex="1"
          aria-label="Menu"
          class="sidebar-nav-toggle-icon"
          :class="{ 'sidebar-nav-toggle-open': !sidebarNavCollapsed }"
          @click="toggleSidebarNav()"
          v-on:keyup.enter="toggleSidebarNav()"
        >
          <img
            src="../assets/img/hamburger-black.svg"
            class="d-block hamburger-icon"
          />
          <img
            src="../assets/img/sidebar-nav-x-icon.svg"
            alt="cross icon"
            class="d-none cross-icon"
          />
        </div>
        <div class="header-logo-div">
          <img
            src="../assets/img/cambridge-logo-black.svg"
            alt="cambridge logo"
            class="img-responsive"
          />
        </div>
      </div>
      <div class="d-flex align-items-center rightsidediv">
        <div class="d-flex align-items-center flex-column searchBarDiv" v-if="hasValueInURL" >
          <SearchBar tabindex="1" />
        </div>
        <!-- <div v-if="isMobile && isSearchBarVisible"
        @click="displaySearchBox"
         class="align-items-center justify-content-center" style="padding-right: 12px">
          <img src="../assets/img/mobile-search-icon.svg">
        </div> -->
        <!-- <div
          :class="isSearchBarVisible ? 'd-flex' : ''"
          class="search-bar-wrapper align-items-stretch"
          style="margin-right: 6%"
        >
          <div class="search-bar d-flex align-items-center">
            <input
              tabindex="1"
              aria-label="Search box, Press enter to search for learning activities."
              v-on:keyup.enter="handleKeyUp"
              @keyup="handleKeyUp"
              v-on:focus="displayClearButton()"
              type="text"
              class="form-control searchBox"
              id="search-input-id"
              placeholder="Search Learning Activities"
            />
            <button 
              tabindex="1"
              aria-label="Clear search field"
              v-on:keyup.enter="clearSearchBox"
              @click="clearSearchBox"
              class="btn btn-default closeButton">
              <i class="bi bi-x-lg" style="color: #000;"></i>
            </button>
          </div>
          
          <div
            tabindex="1"
            aria-label="Press enter to Search your query"
            v-on:keyup.enter="displaySearchBox"
            @click="displaySearchBox"
            class="search-icon d-flex align-items-center"
            role="button"
          >
            <img
              src="../assets/img/search-icon-black.svg"
              alt="search icon"
              class="d-block"
              @click="displaySearchBox"
            />
          </div>
          // <div class="d-flex">
              //<span>Recent searches</span>
              //<button>Clear</button>
           // </div>
          <ul class="historyItems" style="display: none">

          </ul>
          <div class="deleteHistory" style="display: none">
            <span
              tabindex="2"
              aria-label="Clear all searches"
              @click="removeAllSearchHistory"
            >
              <i class="fa fa-trash-o"></i> Clear all searches
            </span>
          </div>
          <div
            class="noResultForSearch"
            style="display: none"
            :aria-label="noSearchSuggestionsAriaLabel"
          >
            <span>No item matches your search keywords</span>
            <span></span>
          </div>
          <div class="minCharErrorDiv" style="display: none">
            <span>Enter 3 or more characters to search</span>
          </div>
        </div> -->
        <!-- <div class="notification-icon" :class="toggleNotificationPanel ? 'notification-panel-open': ''">
					<img src="../assets/img/bell-icon-black.svg" alt="bell icon" class="d-block" role="button" @click="toggleNotificationPanel = !toggleNotificationPanel"/>
          <div class="notification-panel" v-show="toggleNotificationPanel">
            <div class="notification-panel-heading">
              Notifications
            </div>
            <div class="notification-panel-content">
              No notifications
            </div>
          </div>
				</div> -->
        <div
          tabindex="1"
          aria-label="Account button, press enter to view account settings"
          class="profile-icon"
          @click="toggleProfileDropdown = !toggleProfileDropdown"
          v-on:keyup.enter="toggleProfileDropdown = !toggleProfileDropdown"
          ref="userDropdown"
        >
          <i class="bi bi-person-circle d-inline-block" role="button"></i>
        </div>
        <div 
        class="profile-dropdown">
          <span
            tabindex="1"
            aria-label="Account button, press enter to view account settings"
            @click="toggleProfileDropdown = !toggleProfileDropdown"
            class="profile-dropdown-icon"
            :class="toggleProfileDropdown ? 'profile-dropdown-open' : ''"
            role="button"
            v-on:keyup.enter="toggleProfileDropdown = !toggleProfileDropdown"
            ref="userDropdown"
          >
            <img src="../assets/img/down-arrow-black.svg" class="d-block" />
          </span>
          <div class="profile-dropdown-menu" v-show="toggleProfileDropdown" ref="profileDetails">
            <div class="profile-details d-flex align-items-center w-100">
              <div class="logged-in-user-img">
                <i class="bi bi-person-circle d-inline-block"></i>
              </div>
              <div class="logged-in-user-details">
                <span class="logged-in-user-name" id="userName01">{{
                  userName01 || "Cambridge User"
                }}</span
                ><br />
                <span class="logged-in-user-id" id="userEmail1"></span>
              </div>
            </div>
            <div class="user-options-wrapper w-100">
              <div
                class="disableOption user-options d-flex align-items-center justify-content-between w-100"
              >
                <span class="d-inline-block">Dark mode</span>
                <div class="form-check form-switch dark-mode-toggle">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                  />
                </div>
              </div>
              <div class="disableOption user-options w-100">Tech Support</div>
            </div>
            <div class="sign-out-div w-100">
              <button
                type="button"
                class="btn btn-outline-primary w-100"
                @click="signOut"
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Cookies from "js-cookie";
import SearchBar from "./SearchBar.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import skipToMainContent from "../components/skipToMainContent/skipToMainContent.vue"

export default {
  components:{
    SearchBar,
    skipToMainContent,
  },
  props: {},
  data() {
    return {
      toggleProfileDropdown: false,
      toggleNotificationPanel: false,
      isMobile: true,
      userName:'Cambridge User',
      userEmail:'cambridge@cambridge.in',
      noSearchSuggestionsAriaLabel:
        "No search suggestions found. Enter to search typed query",
      searchQuery: '',
      showRecent: false,
      showSuggestions: false,
      suggestedSearches: [],
    };
  },
  computed: {
    hasValueInURL() {
      // Extract the value from the URL (assuming the value is in the query parameter 'value')
      const currentURL = window.location.href;
      const pathname = new URL(currentURL).pathname;
      const pathParts = pathname.split('/');
      const courseId = pathParts[pathParts.length - 2];
      console.log("HasValueInURL--- ",courseId);
      console.log(this.$route.name);
      if (courseId === this.$store.state.objID){
        return true;
      }else{
        return false;
      }
    },
    recentSearches() {
      return this.$store.state.recentSearches;
    },
    isSearchBarVisible() {
      var isSearchBarVisible = this.$store.state.isSearchBarVisible;
      return isSearchBarVisible;
    },
    ...mapState(["sidebarNavCollapsed", "userName", "userEmail"]),
  },
 mounted() {

  this.checkScreenSize();
  window.addEventListener("resize", this.checkScreenSize);

  var userName01;
  if(this.isMobile){
    $(".searchBox").hide();
    $(".verticalBarbetween").hide();
    //this.displayClearButton();
    $(".historyItems").hide();
    $(".search-bar").hide();
    $("#search-input-id").css("display", "none")
    $(".search-bar").removeClass("typing");
    $(".closeButton").hide();
  }else {
    $(".searchBox").show();
    $(".closeButton").hide();
  }
  var cbjwt = getParameterByName("cbjwt");
  // let userEmail = this.$store.state.userEmail;
  // let userName;
  console.log("T", cbjwt);
  if (cbjwt) {
    console.log(" cbjwt ------------- " + cbjwt);
    var payload = cbjwt.split(".")[1];
    console.log(" payload ----------- " + payload);
    var decodePayLoad = window.atob(payload);
    console.log(" decodePayLoad ---------- " + decodePayLoad);
    var payloadJSON = JSON.parse(decodePayLoad);
    console.log(" payloadJSON ---------- " + payloadJSON);
    var classId=  payloadJSON.course;
    var uid = payloadJSON.uid;
    console.log("class id --------------- "+classId)
    console.log("user id ---------------- "+uid)
    var objID = getParameterByName("COURSEID");  
    console.log("7777   "+objID);
    document.cookie = "cbjwt=" + cbjwt;
  }

  this.$nextTick(() => {
    function getEmailID() {
      var name = 'email';
      var url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    function getuserName() {
      var url = window.location.href;
      const url1 = new URL(url);
      const param1Value = url1.searchParams.get('cbjwt');
      if (param1Value) {
        var payload = param1Value.split(".")[1];
        var decodePayLoad = window.atob(payload);
        var payloadJSON = JSON.parse(decodePayLoad);
        userName01 = payloadJSON.full_name;
        console.log(userName01);
      }
      return userName01;
    }

    var userEmail1 = getParameterByName("EMAIL");
    var userName011 = getuserName();
    this.$store.dispatch('userEmail', userEmail1);
    this.$store.dispatch('userName', userName011);
    if(userEmail1){
      sessionStorage.setItem("userEmail1", userEmail1);
      sessionStorage.setItem("userName011", userName011);
    }
    userEmail1 = sessionStorage.getItem("userEmail1");
    userName011 = sessionStorage.getItem("userName011");
    document.getElementById('userName01').innerText = userName011;
    document.getElementById('userEmail1').innerText = userEmail1;
    console.log(userEmail1, userName011)
  });
},

  methods: {
    handleClickOutside(event) {
      const profileDetails = this.$refs.profileDetails;
      if (profileDetails && !profileDetails.contains(event.target)) {
        // Clicked outside the profile component, hide the profile details
        this.toggleProfileDropdown = false;
      }
    },
    removeSearchItem(index) {
      this.recentSearches.splice(index, 1);
    },
    userDropdown(e){
      let el = this.$refs.userDropdown;
      let target = e.target;
      if (el !== target && !el.contains(target)){
        this.toggleProfileDropdown = false
      }
    },
    checkScreenSize() {
      // Update isDesktop based on screen width
      this.isMobile = window.innerWidth < 768; // Example threshold for desktop
    },
    ...mapActions([
      "toggleSidebarNav",
      "cbjwtToken",
      "cbjwtTokenPayload",
      "userNameAction",
      "userEmailAction",
    ]),
    signOut() {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href =
        "https://qa4.cogbooks.com/communicog/handler?action=logoutSession";
    },
    getSetValueByIndex(setObj, index) {
      return [...setObj][index];
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\$&"); // $& means the whole matched string
    },
    getIndicesOf(searchStr, str, caseSensitive) {
      var searchStrLen = searchStr.length;
      if (searchStrLen == 0) {
        return [];
      }
      var startIndex = 0,
        index,
        indices = [];
      if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
      }
      while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
      }
      return indices;
    },
    escapeRegExp1(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    },
  },
  beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>

<style lang="scss">
.form-check-input{
  background-color: #9E9E9E !important;
}
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/%3e%3c/svg%3e") !important;
}
.form-check-input:focus{
  box-shadow: 0 0 0 0.25rem rgba(114, 114, 114, 0.25) !important;
  border-color: #9E9E9E !important;
}
.form-check-input:checked{
  border-color: #9E9E9E !important;
}
// .form-switch .form-check-input:focus {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='pink'/%3e%3c/svg%3e");
// }
  .typing .seach-button-div {
      background: #E1F5FE;
  }

  .typing .inputBoxLeftVerticalBar {
      border-color: #0288D1;
  }

  .typing 
  input.seachBox {
      color: #0288D1;
  }

  .typing .seach-button-div button.btn.closeButton {
      background-color: #4fc3f7;
      color: #fff;
      border-radius: 50%;
      padding: 0px;
  }
.noResultForSearch {
  width: 348px;
  height: 48px;
  background: #fff;
  box-shadow: 0px 2px 4px #0000004d;
  opacity: 1;
  position: absolute;
  margin-top: 3.0%;
}
.dark-mode-toggle{
  padding:0 !important;
  align-items: center;
  justify-content: center;
  display: flex;
}
.dark-mode-toggle>input{
  margin: 0 !important;
}
.noResultForSearch span {
  line-height: 16px;
  font-family: "Source Sans 3";
  opacity: 1;
  color: #9e9e9e;
  margin: 16px 10px;
  font-size: 16px;
  text-align: left;
  float: left;
}
.bi-search{
  font-size: small;
  display: flex;
  align-items: center;
  color: #9E9E9E;
  -webkit-text-stroke: 1px !important;
}
ul.historyItems {
  padding: 20px 16px 24px 16px;
  margin-bottom: 0;
  max-height: max-content;
  /* overflow-y: auto; */
  background-color: #ffffff;
  margin-top: 0;
  overflow: hidden;
  width: 348px;
  position: absolute;
  margin-top: 3.0%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3019607843);
  border: 1px solid #9E9E9E;
  border-radius: 4px;
}
.historyItems li:first-child {
  margin-top: 6px;
}
li.searchSuggestions {
  padding-left: 12px;
  padding-right: 4px;
}
.historyItems li {
  padding-left: 12px;
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 4px 2px;
  cursor: pointer;
  font-family: "Source Sans 3";
  color: #424242;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.minCharErrorDiv {
  width: 348px;
  height: 48px;
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3019607843);
  opacity: 1;
  position: absolute;
  margin-top: 3.5%;
  border: 1px solid rgb(158, 158, 158);
  border-radius: 4px;
}
.minCharErrorDiv span {
  line-height: 16px;
  font-family: "Source Sans 3";
  opacity: 1;
  color: #212121;
  margin: 16px 10px;
  font-size: 16px;
  text-align: left;
  float: left;
}
.rightsidediv{
  gap: 16px;
}
// .searchBarDiv{
//   margin-top:180px;
// }
.header-div {
  float: left;
  width: 100%;
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #00000014;
  padding-left: 11px;
  padding-right: 30px;
}

.header-logo-div img {
  width: 190px;
}

.sidebar-nav-toggle-icon {
  padding: 16px 13px;
  margin-right: 12px;

  &:hover {
    background-color: rgb(243, 243, 243);
    border-radius: 50%;
    cursor: pointer;
  }
}

.profile-icon {
  position: relative;

  i {
    font-size: 44px;
    line-height: 44px;
    color: rgba(158, 158, 158, 1);
  }

  &::before {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    content: "";
    background-color: rgb(0 0 0 / 25%);
    border-radius: 50%;
    opacity: 0;
    transition: 0.5s;
    cursor: pointer;
  }

  &:hover::before {
    opacity: 1;
  }
}

.notification-icon {
  margin: 0px 10px;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  .notification-panel {
    position: fixed;
    right: 16px;
    top: 68px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid rgb(158, 158, 158);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    width: 360px;

    .notification-panel-heading {
      font: normal normal bold 20px Lato-Regular;
      color: rgb(33, 33, 33);
      padding: 20px 16px;
      border-bottom: 1px solid rgb(238, 238, 238);
    }

    .notification-panel-content {
      padding: 16px;
    }
  }
}

.notification-panel-open {
  background-color: rgb(243, 243, 243);
  border-radius: 50%;
}

.search-bar-wrapper {
  border: 1px solid #9E9E9E;
  height: 44px;
  border-radius: 4px;
  width: 375px;
  display: none;
}

.search-icon {
  width: 55.5px;

  img {
    margin: auto;
  }
}
.search-bar {
  flex-grow: 1;
  border-right: 1px solid #9E9E9E;

  input {
    font-size: 16px;
    color: rgb(66, 66, 66);
    font-family: "Source Sans 3";
    border: none;
  }

  input:focus {
    box-shadow: none;
  }
}

.logged-in-user-img {
  margin-right: 8px;
  color: #9E9E9E;

  i {
    font-size: 56px;
    line-height: 56px;
  }
}
.profile-details {
  padding: 16px;
}

.profile-dropdown {
  .profile-dropdown-menu {
    position: fixed;
    right: 16px;
    top: 68px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid rgb(158, 158, 158);
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
  }

  .profile-dropdown-icon {
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    transform-origin: center;
    display: block;
  }

  .profile-dropdown-icon.profile-dropdown-open {
    transform: rotate(180deg);
  }
}

.user-options-wrapper {
  border-top: 1px solid rgb(224, 224, 224);
  border-bottom: 1px solid rgb(224, 224, 224);
  padding: 8px 0px;
}

.user-options {
  padding: 12px 16px;
  font: normal normal normal 16px/24px "Source Sans 3";

  .dark-mode-toggle {
    margin-bottom: 0px;

    input {
      width: 32px;
      height: 18px;
    }
  }

  &:hover {
    background-color: rgb(243, 243, 243);
    color: rgb(33, 33, 33);
    cursor: pointer;
  }
}

.sign-out-div {
  padding: 16px;
}

.sign-out-div {
  button {
    font: normal normal 600 16px/24px "Source Sans 3";
    color: rgb(84, 101, 248);
    border-color: rgb(84, 101, 248);
  }

  button:hover {
    background-color: #EEF0FE;
    border-color: rgb(84, 101, 248);
    color: #4756D3;
  }
}

.logged-in-user-name {
  font: normal normal bold 16px/24px "Source Sans 3";
}

.logged-in-user-id {
  font: normal normal normal 16px/24px "Source Sans 3";
  color: rgb(66, 66, 66);
}
@media only screen and (min-width: 1440px) {
  .minCharErrorDiv{
    margin-top: 3.1%;
  }

}
@media only screen and (max-width: 600px) {
  ul.historyItems{
    margin-top: 12%;
  }
  .noResultForSearch{
    margin-top: 12%;
  }
  .minCharErrorDiv{
    margin-top: 12%;
  }
  .search-bar{
    border-right: none;
  }
  .search-bar-wrapper{
    width: fit-content;
    border: none;
  }
  .searchBox{
    display: none;
  }
  .header-div{
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
@media only screen and (max-width: 1023px) {
  .header-logo-div {
    display: none;
  }
  .rightsidediv{
  gap: 1px !important;
}

  .sidebar-nav-toggle-open {
    .hamburger-icon {
      display: none !important;
    }

    .cross-icon {
      display: block !important;
    }
  }

  .sidebar-nav-toggle-icon:hover {
    background-color: initial;
  }

  .profile-icon {
    margin: 0px;
    i {
      font-size: 36px;
      line-height: 36px;
    }
  }

  .profile-dropdown-icon {
    display: none !important;
  }

  .header-div {
    height: 56px;
    padding: 0px 16px;
  }

  .logged-in-user-img i {
    font-size: 44px;
    line-height: 44px;
  }

  .logged-in-user-name {
    font: normal normal bold 14px/18px "Source Sans 3";
  }

  .logged-in-user-id {
    font: normal normal normal 14px/18px "Source Sans 3";
  }

  .profile-dropdown .profile-dropdown-menu {
    top: 50px;
  }

  .notification-icon .notification-panel {
    width: 344px;
  }
}
.btn:first-child:active{
  background-color: #ffffff !important;
  color: rgb(84, 101, 248) !important;
}

.disableOption{
  pointer-events: none;
  opacity: 0.3;
}
</style>
