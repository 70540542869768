<template>
  <div class="placeholder" :style="placeholderStyle">&nbsp;</div>
</template>

<script>
export default {
  name: "AnimatedPlaceholder",
  props: {
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    borderRadius: {
      type: String,
    },
  },
  computed: {
    placeholderStyle() {
      return {
        height: this.height,
        width: this.width,
        borderRadius: this.borderRadius,
        backgroundImage:
          "linear-gradient(to right, #d6d7d8 0%, #e2e3e4 10%, #d6d7d8 20%, #d6d7d8 100%)",
        backgroundSize: "200% 100%",
        boxShadow: "0 4px 6px -1px #EEEEEE, 0 2px 4px -2px #EEEEEE",
      };
    },
  },
};
</script>

<style scoped>
@keyframes bgAnimate {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}
.placeholder {
  height: v-bind(height);
  width: v-bind(width);
  border-radius: v-bind(borderRadius);
  background-image: linear-gradient(
    to right,
    #d6d7d8 0%,
    #e2e3e4 10%,
    #d6d7d8 20%,
    #d6d7d8 100%
  );
  background-size: 200% 100%;
  box-shadow: 0 4px 6px -1px #eeeeee, 0 2px 4px -2px #eeeeee;
  animation: bgAnimate 1.2s linear infinite;
}
</style>
