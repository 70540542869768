<template>
      <div class="card">
        <div class="card-graphic">
          <AnimatedPlaceholder height="180px" width="100%" borderRadius="5px" />
        </div>
        <div class="card-content">
          <div class="d-flex justify-content-between card-title-wrapper">
            <h2 class="card-title pl-2">
              <AnimatedPlaceholder height="20px" width="250px" borderRadius="5px" />
              <AnimatedPlaceholder height="20px" width="250px" borderRadius="5px" />
            </h2>
            <span class="startArrow px-3 pt-2">
              <AnimatedPlaceholder height="30px" width="20px" borderRadius="5px" />
            </span>
          </div>
          <div class="card-details d-flex gap-2 pb-2">
            <div class="card-topics">
              <AnimatedPlaceholder height="20px" width="80px" borderRadius="5px" />
            </div>
            <div class="card-duration">
              <AnimatedPlaceholder height="20px" width="80px" borderRadius="5px" />
            </div>
          </div>
          <div class="card-progress-div">
            <div class="card-progress">
              <div
                class="card-progress-bar"
              >
              <AnimatedPlaceholder height="15px" width="315px" borderRadius="5px" />
            </div>
            </div>
          </div>
        </div>
      </div>
  </template>
<script>
import AnimatedPlaceholder from "../animations/AnimatedPlaceholder.vue";

export default{
  name: "CourseListingSkelton",
  components: { AnimatedPlaceholder },
}


</script>