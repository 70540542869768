<template>
    <div class="assigment-tab" v-if="isMobile" >
        <img src="../../assets/img/mobileSkeltonImage2.png" alt="loading skelton" width="100%"/>
        
    </div>
    <div v-else class="assigment-tab">
        <img src="../../assets/img/skeltonImageCourseOutline.png" alt="loading skelton"  width="auto"/>
        
    </div>
</template>

<script>
export default {
    name: "Assignments",
    data(){

        return{
            isMobile: true,
        }
    },
    mounted() {
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
    // Remove event listener when the component is destroyed
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods:{
    checkScreenSize() {
      // Update isDesktop based on screen width
      this.isMobile = window.innerWidth < 890; // Example threshold for desktop
    }
  },
}
</script>

<style scoped>
.assigment-tab{
    height: 100%;
    width: 100%;
    
}
img{
    position: relative;
    overflow: hidden;
}

</style>