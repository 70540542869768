<template>
  <nav>
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['nav-link', { active: activeTabIndex === index }]"
        :id="`nav-${tab.id}-tab`"
        :data-bs-toggle="activeTabIndex !== index ? 'tab' : ''"
        :data-bs-target="activeTabIndex !== index ? `#nav-${tab.id}` : ''"
        type="button"
        role="tab"
        :aria-controls="`nav-${tab.id}`"
        :aria-selected="activeTabIndex === index"
        :tabindex="activeTabIndex === index ? 0 : -1"
        @click="handleTabClick(index)"
        :aria-label="`You are currently on the ${tab.name} Tab. ${tab.ariaLabel}`"
      >
        {{ tab.name }}
      </button>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      :class="['tab-pane', 'fade', { 'show active': activeTabIndex === index }]"
      :id="`nav-${tab.id}`"
      role="tabpanel"
      :aria-labelledby="`nav-${tab.id}-tab`"
    >
      <Assignments />
    </div>
  </div>
</template>

<script>
import Assignments from "../assignments/Assignments.vue";
export default {
  name: "CoursePagePagination",
  components: {
    Assignments,
  },
  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        {
          id: "course-outline",
          name: "Course Outline",
          ariaLabel:
            "Here you’ll see the list of topics and their corresponding subtopics",
        },
        {
          id: "grades",
          name: "Grades",
          ariaLabel: "Press enter to view the grades",
        },
        {
          id: "highannotes",
          name: "Highlights and Notes",
          ariaLabel:
            "Press enter to view the Highlights and Notes that you have saved",
        },
        {
          id: "assignments",
          name: "Assignments",
          ariaLabel: "Press enter to view list of all the assignments",
        },
      ],
    };
  },
  methods: {
    handleTabClick(index) {
  const navTab = document.getElementById('nav-tab');
  const clickedTab = navTab.children[index];
  const navTabRect = navTab.getBoundingClientRect();
  const clickedTabRect = clickedTab.getBoundingClientRect();

  // Calculate the scroll position to center the clicked tab
  let scrollLeft = clickedTabRect.left - navTabRect.left - (navTabRect.width - clickedTabRect.width) / 2;

  // Adjust scroll position to show adjacent tabs
  if (index === 2) { // Clicked on the 3rd tab
    scrollLeft -= clickedTabRect.width; // Shift to show the 3rd tab in center
    navTab.children[0].style.display = 'none'; // Hide the 1st tab
    if(clickedTabRect.right === 198.44){ 
        console.log("hello")
    }
  } else if (index === 3) { // Clicked on the 4th tab
    scrollLeft -= clickedTabRect.width * 2; // Shift to show the 4th tab in center
    navTab.children[0].style.display = 'none'; // Show the 1st tab
    navTab.children[1].style.display = 'none';
  }

  navTab.scrollTo({ left: scrollLeft, behavior: 'smooth' });
  this.activeTabIndex = index;
},
    scrollTabs(scrollIndex) {
      const navTab = document.getElementById("nav-tab");
      const targetTab = navTab.children[scrollIndex];
      const scrollLeft =
        targetTab.offsetLeft -
        navTab.offsetWidth / 2 +
        targetTab.offsetWidth / 2;
      navTab.scrollTo({ left: scrollLeft, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
nav {
  max-width: 100vw;
  overflow: hidden;
}
.nav-tabs .nav-link {
  font: normal normal 600 16px/24px "Source Sans 3";
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
}
.nav-tabs .nav-link:focus {
  border: none;
}

.nav-tabs .nav-link.active {
  color: #4756d3;
  background-color: #f7f7f7;
  border-bottom: 3px solid #4756d3;
  transition: color .30s ease-out;
}
#nav-assignments {
  position: relative;
}
#nav-tab {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
}
@media only screen and (min-width: 768px) {
  /* Adjust the styling for desktop screens */
  #nav-tab {
    overflow-x: visible; /* Display all tabs without scrolling */
  }
}
</style>
