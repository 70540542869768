<script setup>
  import Header from './components/Header.vue';
  import LeftNavigation from './components/LeftNavigation.vue';
  import Footer from './components/Footer/Footer.vue';
  var cbjwt = getParameterByName('cbjwt');
  //document.cookie = "cbjwt=" + cbjwt
  sessionStorage.setItem("cbjwt",cbjwt)

</script>
<template>
  <div class="float-start w-100">
    <Header />
    <div id="page-wrapper">
      <LeftNavigation />
      <div id="page-content">
        <router-view />
      </div>
    </div>
    <Footer />
  </div>
</template>

<style>
header {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
}

#page-wrapper {
  display: flex;
  float: left;
  width: 100%;
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  align-items: stretch;
}

#page-content {
  flex-grow: 1;
}

@media only screen and (max-width: 1023px) {
  .empty-placeholder {
    display: none;
  }

  #page-wrapper {
    margin-top: 56px;
    min-height: calc(100vh - 56px);
  }
}

</style>
