<template>
  <nav>
    <div
      class="nav nav-tabs"
      ref="navTabs"
      id="nav-tab"
      role="tablist"
      :style="{ transform: `translateX(${tabScroll}px)` }"
    >
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['nav-link', { active: activeTabIndex === index }]"
        :id="`nav-${tab.id}-tab`"
        data-bs-toggle="tab"
        :data-bs-target="`#nav-${tab.id}`"
        type="button"
        role="tab"
        :aria-controls="`nav-${tab.id}`"
        :aria-selected="activeTabIndex === index"
        tabindex="1"
        :aria-label="tab.ariaLabel"
        @click="switchTab(index)"
        style="transition: 0.5s ease-in"
      >
        {{ tab.label }}
      </button>
    </div>
    <div class="nav-overlay"></div>
  </nav>
  <div class="tab-content" id="nav-tabContent">
    <component
      v-if="tabs[activeTabIndex]"
      :is="tabs[activeTabIndex].component"
      :class="['tab-pane', { 'show active': activeTabIndex === index }]"
      :id="`nav-${tabs[activeTabIndex].id}`"
      role="tabpanel"
      :aria-labelledby="`nav-${tabs[activeTabIndex].id}-tab`"
    />
  </div>
</template>

<script>
//import Assignments from "../assignments/Assignments.vue";
import CourseOutline from "../courseOutline/CourseOutline.vue";
import HighlightsAndNotes from "../highlightsAndNotes/HighlightsAndNotes.vue";
import Grades from "../grades/Grades.vue";
import Review from "../review/Review.vue";

export default {
  name: "CoursePagePagination",
  components: {
    //Assignments,
    CourseOutline,
    HighlightsAndNotes,
    Grades,
    Review,
  },
  data() {
    return {
      tabs: [
        {
          id: "course-outline",
          label: "Course Outline",
          ariaLabel:
            "You are currently in the Course outline Tab. Here you’ll see the list of topics and their corresponding subtopics",
          component: "CourseOutline",
        },
        {
          id: "highannotes",
          label: "Highlights and Notes",
          ariaLabel:
            "You are currently on the “Highlights and Notes” Tab. Press enter to view the Highlights and Notes that you have saved",
          component: "HighlightsAndNotes",
        },
        // {
        //   id: "assignments",
        //   label: "Assignments",
        //   ariaLabel:
        //     "You are currently on the “Assignment” Tab. Press enter to view list of all the assignments",
        //   component: "Assignments",
        // },
      ],
      activeTabIndex: 0,
      tabScroll: 0,
      isMobile: false,
      gradesFlag: null,
      apaFlag: null,
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);

    var cbjwt = getParameterByName("cbjwt");
    if (cbjwt) {
      var payload = cbjwt.split(".")[1];
      var decodePayLoad = window.atob(payload);
      var payloadJSON = JSON.parse(decodePayLoad);
      this.gradesFlag = payloadJSON.auth_res;
      this.apaFlag = payloadJSON.lti_consumer;

      this.updateTabs();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768;
    },
    switchTab(index) {
      this.activeTabIndex = index;
      this.scrollTabIntoView(index);
    },
    scrollTabIntoView(index) {
      const tabWidth = this.$refs.navTabs.children[index].offsetWidth;
      const tabsContainerWidth = this.$refs.navTabs.offsetWidth;
      const totalTabsWidth = this.$refs.navTabs.scrollWidth;
      const tabsToEnd = totalTabsWidth - (this.tabScroll + tabsContainerWidth);
      if (this.isMobile) {
        if (tabWidth > tabsContainerWidth) {
          this.tabScroll = 0;
        } else if (tabsToEnd < tabWidth) {
          this.tabScroll -= 100;
        } else if (this.tabScroll < 0) {
          this.tabScroll += 100;
        } else {
          this.tabScroll = 0;
        }
      }
    },
    updateTabs() {
      const index = this.tabs.findIndex(tab => tab.id === "grades" || tab.id === "review");
      if (index !== -1) {
        this.tabs.splice(index, 1);
      }

      
      if (this.gradesFlag !== "-1") {
        this.tabs.splice(1, 0, {
          id: "grades",
          label: "Grades",
          ariaLabel: "You are currently on the “Grades” Tab. Press enter to view the grades",
          component: "Grades",
        });
      } else if (this.apaFlag && this.apaFlag.includes("APA")) {
        this.tabs.splice(1, 0, {
          id: "review",
          label: "Review",
          ariaLabel: "You are currently on the “Review” Tab. Press enter to review your course content",
          component: "Review",
        });
      }
    },
  },
};
</script>

<style scoped>
nav {
  max-width: 100vw;
  overflow: hidden;
}
.nav-tabs .nav-link {
  font: normal normal 600 16px/24px "Source Sans 3";
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
}
.nav-tabs .nav-link:focus {
  border: none;
}

.nav-tabs .nav-link.active {
  color: #4756d3;
  background-color: #f7f7f7;
  border-bottom: 3px solid #4756d3;
  transition: color 0.3s ease;
}
#nav-assignments {
  position: relative;
}
#nav-tab {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
#nav-tab::-webkit-scrollbar {
  display: none;
}
#nav-review{
  display: block;
}
#nav-highannotes{
  display: block;
}
#nav-grades{
  display: block;
}
@media only screen and (max-width: 768px) {
  #nav-tab {
    gap: 25px;
  }
  .nav-tabs {
    position: relative;
  }
  .nav-overlay {
    content: "";
    position: absolute;
    top: 63%;
    right: 14px; /* Adjust this value as needed */
    width: 50px; /* Adjust this value as needed */
    height: 5%;
    background: linear-gradient(
      to right,
      transparent,
      white
    ); /* Adjust gradient as needed */
    pointer-events: none; /* Allows clicking through the overlay */
    border-radius: 4px;
  }
}
</style>
