<template>
  <div>
    <div class="reviewinfo">Find quick access to all module summaries here.</div>
      <iframe class="iFrame":src="jspUrl" width="100%" height="600px" frameborder="0"></iframe>
  </div>
</template>

<script>
import { CB_END_POINT } from "../../config/config";

export default {
  name:"Review",
  mounted(){
      if(this.$route.params.id){
          this.courseid = this.$route.params.id;
    }else{
      this.courseid = getParameterByName("COURSEID");
    }
      // this.jspUrl = CB_END_POINT+'/handler?action=myLearningToolkit&COURSEID='+this.courseid+'&Page=LD';
      this.jspUrl = CB_END_POINT+'/handler?action=myLearningToolkit&COURSEID='+this.courseid+'&Page=LD&Component=Review';
  },
  setup () {
      

      return {}
  },
  data(){
      return{
          courseid: '',
          jspUrl: '',
      }
  },

}
</script>

<style lang="scss" scoped> 
.reviewinfo{
  width: 322px;
    height: 20px;
    text-align: left;
    font: normal normal normal 16px / 24px Source Sans Pro;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-top: 16px;
    margin-bottom: 24px;
}
</style>
