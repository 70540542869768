<template>
  <router-link to="/courses" @click-native="scrollToTop">
    <div v-if="loading" class="skeleton-loader">
      <CourseListingSkelton />
    </div>
    <div class="card" v-else>
      <div class="card-graphic">
        <img
          class="course-image"
          :src="`https://qa4.cogbooks.com/cogContent/newui/content/course/coverImage/course_${courseID}.jpg`"
          @error="handleImageError"
          alt=""
        />
      </div>
      <div class="card-content">
        <div class="d-flex justify-content-between card-title-wrapper">
          <h2 class="card-title">{{ truncatedTitle }}</h2>
          <span class="startArrow">
            <img src="../../../public/images/Path_1547.svg" alt="" />
          </span>
        </div>
        <div class="card-details">
          <div class="card-topics">
            <img src="../../../public/images/topics-icon.svg" alt="" />
            <span> {{ topics }} topics</span>
          </div>
          <span class="card-details-span">|</span>
          <div class="card-duration">
            <i class="clockIcon bi bi-clock"></i>
            <span>{{ duration }}</span>
          </div>
        </div>
        <div class="card-progress-div">
          <div class="card-progress">
            <div
              class="card-progress-bar"
              :style="{ width: progress + '%' }"
            ></div>
          </div>
          <span class="progressSpan">{{ progress }}%</span>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import { DEFAULT_IMAGE } from "@/config/config";
import { useRoute } from "vue-router";
import Cookies from "js-cookie";
import { mapState, mapActions, mapMutations } from "vuex";
import CourseListingSkelton from "../skeltons/CourseListingSkelton.vue";

export default {
  name: "CourseListingData",
  props: {
    courseID: String,
    title: String,
    topics: String,
    duration: String,
    progress: Number,
  },
  computed: {
    truncatedTitle() {
      const maxLength = 60;
      if (this.title.length <= maxLength) {
        return this.title;
      } else {
        return this.title.slice(0, maxLength) + "...";
      }
    },
    ...mapState(["courseListing", "courseDetails"]),
  },
  methods: {
    scrollToTop() {
    window.scrollTo(0,0);
  },
    ...mapActions([
      "fetchCourseListing",
      "cbjwtToken",
      "cbjwtTokenPayload",
      "fetchCourseDetails",
    ]),
    ...mapMutations(["updateJwtPayload"]),
    handleImageError(event) {
      event.target.src = DEFAULT_IMAGE; // Path to your default image
    },
  },
  data() {
    return {
      visible: false,
      loading: true,
    };
  },
  created(){
    this.$store.dispatch("userNameAction"),
    this.$store.dispatch("userEmailAction")
  },
  mounted() {
    //console.log("Get Cookie values :" + document.cookie.split(';'));
    console.log("Inside CouseListing");
    const route = useRoute();
    var cbjwt = route.query.cbjwt;
    if (cbjwt) {
      console.log(" cbjwt ------------- " + cbjwt);
      this.cbjwtToken({ cbjwt });
      var payload = cbjwt.split(".")[1];
      console.log(" payload ----------- " + payload);
      var decodePayLoad = window.atob(payload);
      console.log(" decodePayLoad ---------- " + decodePayLoad);
      var payloadJSON = JSON.parse(decodePayLoad);
      this.cbjwtTokenPayload({ payloadJSON });
      console.log(" payloadJSON ---------- " + payloadJSON);
      var username = payloadJSON.full_name;
      var uid = payloadJSON.uid;
      console.log(" payloadJSON ---------- " + uid);
      this.fetchCourseListing({ userID: uid }).then(()=>{
        this.loading = false;
      });
      document.cookie = "cbjwt=" + cbjwt;
    }
    // setTimeout(() => {
    //   this.loading = false;
    // }, 3000);
  },
  components: { CourseListingSkelton },
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 396px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e0e0;
  cursor: pointer;
}
.card-graphic {
  height: 180px;
  background-color: #0078d7;
  border-radius: 10px 10px 0px 0px;
}
.clockIcon{
  -webkit-text-stroke: 1px;
  font-size: large;
}
.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: 55%;
}
.startArrow {
  color: #424242;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.startArrow:hover{
  background-color: #F3F3F3;
  border-radius: 50%;
}
.card-title {
  margin: 0;
  font: normal normal bold 20px/28px Lato;
  color: #212121;
  font-family: "SourceSansPro-Regular";
  max-width: 266px;
}
.course-image {
  height: 180px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
}
.card-details {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 16px;
  color: #424242;
  font: normal normal normal 18px/24px Source Sans Pro;
  font-family: "SourceSansPro-Regular";
  flex: 1 1 0;
}
.card-details-span {
  color: #e0e0e0;
}
.card-topics,
.card-duration {
  display: flex;
  align-items: center;
}
.card-topics {
  gap: 5px;
}
.card-topics i,
.card-duration i {
  margin-right: 5px;
}
.card-progress-div {
  flex: 1 1 0;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
}
.card-progress {
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  flex: 4;
}
.card-title-wrapper{
  flex: 2 1 0;
}
.card-progress-bar {
  height: 100%;
  background-color: #00bdb6;
  border-radius: 8px;
}
a {
  text-decoration: none;
}
.progressSpan {
  font: normal normal bold 20px/32px "SourceSansPro-Regular";
  color: #212121;
}
</style>
