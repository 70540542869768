<template>
    <div>
        <iframe class="iFrame":src="jspUrl" width="100%" height="600px" frameborder="0"></iframe>
    </div>
</template>

<script>
import { CB_END_POINT } from "../../config/config";

export default {
    name:"HighlightsAndNotes",
    mounted(){
        if(this.$route.params.id){
            this.courseid = this.$route.params.id;
      }else{
        this.courseid = getParameterByName("COURSEID");
      }
        // this.jspUrl = CB_END_POINT+'/handler?action=myLearningToolkit&COURSEID='+this.courseid+'&Page=LD';
        this.jspUrl = CB_END_POINT+'/handler?action=myLearningToolkit&COURSEID='+this.courseid+'&Page=LD&Component=HN';
    },
    setup () {
        

        return {}
    },
    data(){
        return{
            courseid: '',
            jspUrl: '',
        }
    },

}
</script>

<style lang="scss" scoped>

</style>