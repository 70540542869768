//export const gqendpoint = 'http://localhost/query';
//export const CB_END_POINT = "http://localhost:8888/communicog"
export const CB_END_POINT = "https://qa4.cogbooks.com/communicog"
//export const CB_END_POINT = "https://www.cogbooks.com/communicog"
export const VUE_END_POINT = 'https://ui-qa4.cogbooks.com';
// export const APP_URL = '';
export const DEFAULT_IMAGE= 'https://author.cogbooks.com/cogContent/newui/content/course/header.jpg';
//                    //const apiHostname = 'gqlgw-qa-env.cogbooks.com',
                const apiHostname = 'gqlgw-qa4.cogbooks.com',
                apiProto = 'https',
                apibasepath = '/learner',
                apiResourceLocator = '/course/learnerDashboard/',
                apiVersion = 'v1',
                apiResourceIdentifer = '/gquery'
                export const gqendpoint = apiProto + ":" + "//" + apiHostname + apibasepath + apiResourceLocator + apiVersion + apiResourceIdentifer
                