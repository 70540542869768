<template>
    <div class="container-fluid main-wrapper" v-if="course">
      <div class="row pt-3">
        <div class="col-12 d-flex align-items-center">
            <AnimatedPlaceholder height="15px" width="200px" borderRadius="5px" />
        </div>
      </div>
      <div class="row ">
        <div
          class="col-2 order-1 order-md-1 d-flex align-items-center book-cover-img"
        >
            <AnimatedPlaceholder height="66px" width="66px" borderRadius="5px" />
        </div>
        <div
          class="col-10 order-2 order-md-2 d-flex gap-2 align-items-center courseName"
        >
            <AnimatedPlaceholder height="15px" width="200px" borderRadius="5px" />
            <AnimatedPlaceholder height="15px" width="200px" borderRadius="5px" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
            <AnimatedPlaceholder height="15px" width="200px" borderRadius="5px" />
            <AnimatedPlaceholder height="15px" width="200px" borderRadius="5px" />
            <AnimatedPlaceholder height="15px" width="200px" borderRadius="5px" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex align-items-center" >
            <AnimatedPlaceholder height="15px" width="40px" borderRadius="5px" />
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 d-flex gap-3 align-items-center estimatedTimeAndTopics"
        >
        <AnimatedPlaceholder height="15px" width="50px" borderRadius="5px" />
        <AnimatedPlaceholder height="15px" width="50px" borderRadius="5px" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex gap-3 align-items-center">
            <AnimatedPlaceholder height="15px" width="200px" borderRadius="5px" />
        </div>
      </div>
      <div class="row pb-5">
        <div class="col-12 d-flex">
            <AnimatedPlaceholder height="15px" width="150px" borderRadius="5px" />
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
  import AnimatedPlaceholder from "../animations/AnimatedPlaceholder.vue";

export default{
  name: "CourseDetailSkeltonForMobile",
  components: { AnimatedPlaceholder },
}
  </script>
  
  <style scoped>
  .main-wrapper {
    background-color: #f5f7fc;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .courseBreadcrumb {
    font: normal normal 600 16px/28px "SourceSansPro-Regular";
    letter-spacing: 0px;
    color: #4756d3;
    text-decoration: none;
    cursor: pointer;
  }
  .bi-chevron-right {
    color: #4756d3;
  }
  .courseNameBrd {
    font: normal normal normal 16px/28px "SourceSansPro-Regular";
    letter-spacing: 1px;
    color: #4756d3;
  }
  .ml-2 {
    margin-left: 0.5rem;
  }
  .courseName {
    padding-left: 0;
  }
  .book-cover-img {
    padding-right: 0;
  }
  .book-cover-img img {
    border-radius: 5px;
  }
  .introText {
    font: normal normal normal 16px/24px "SourceSansPro-Regular";
    letter-spacing: 0px;
    color: #424242;
    margin: 0;
  }
  .card-details-span {
    color: #e0e0e0;
  }
  .estimatedTimeAndTopics {
    font: normal normal normal 16px/24px "SourceSansPro-Regular";
    letter-spacing: 1px;
    color: #424242;
  }
  .readMore {
    font: normal normal bold 16px/28px "SourceSansPro-Regular";
    letter-spacing: 1px;
    color: #4756d3;
    text-decoration: none;
  }
  .readMore:hover {
    text-decoration: underline;
  }
  .card-progress-bar {
    background-color: #00bdb6;
  }
  .start_Resume{
    text-transform: lowercase;
    margin: 0;
    display: inline-block;
    color: #FFFFFF;
    font: normal normal 600 16px/22px "Source Sans Pro";
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  .start_Resume::first-letter{
    text-transform: uppercase;
  }
  .progressSpan {
    font: normal normal bold 20px/32px "SourceSansPro-Regular";
    color: #212121;
  }
  .btncls {
    background: #5465f8 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
    width: 100%;
    height: 44px;
    margin: 0px;
    margin-top: 0px !important;
    font: normal normal 600 16px/22px "SourceSansPro-Regular";
    letter-spacing: 1px;
    color: #ffffff;
  }
  .btncls:hover {
    background: #4756d3 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: none;
  }
  .button:focus {
    outline: none;
  }
  @media only screen and (max-width: 600px) {
    .courseName {
      padding-left: 30px;
    }
    .progress {
      width: 82% !important;
    }
  }
  </style>
  