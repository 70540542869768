<template>
    <div class="card cardClass d-flex gap-1">
      <div class="card">
        <AnimatedPlaceholder height="60px" width="100%" borderRadius="5px" />
      </div>
      <div class="card">
        <AnimatedPlaceholder height="60px" width="100%" borderRadius="5px" />
      </div>
      <div class="card">
        <AnimatedPlaceholder height="60px" width="100%" borderRadius="5px" />
      </div>
      <div class="card">
        <AnimatedPlaceholder height="60px" width="100%" borderRadius="5px" />
      </div>
      <div class="card">
        <AnimatedPlaceholder height="60px" width="100%" borderRadius="5px" />
      </div>
      <div class="card">
        <AnimatedPlaceholder height="60px" width="100%" borderRadius="5px" />
      </div>
    </div>
</template>
<script>
import AnimatedPlaceholder from "../animations/AnimatedPlaceholder.vue";

export default{
name: "CourseOutLineSkelton",
components: { AnimatedPlaceholder },
}


</script>
<style>
.cardClass{
  border: none !important;
}

</style>