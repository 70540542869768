<template>
  <div class="float-start w-100 p-4 courses-detail-page-wrapper">
    <div class="details-wrapper">
      <div class="row pb-4">
        <div class="coursePath-placeholder d-flex flex-column gap-3">
          <AnimatedPlaceholder height="20px" width="150px" borderRadius="5px" />
        </div>
      </div>
      <div class="row course-details-intro">
        <div class="course-details float-start d-flex gap-4">
          <div class="course-image">
            <AnimatedPlaceholder
              height="200px"
              width="200px"
              borderRadius="5px"
            />
          </div>
          <div class="course-intro d-flex gap-5">
            <div class="courseName-placeholder d-flex flex-column gap-3">
              <AnimatedPlaceholder
                height="20px"
                width="200px"
                borderRadius="5px"
              />
              <div class="d-flex flex-column gap-1">
                <AnimatedPlaceholder
                  height="20px"
                  width="500px"
                  borderRadius="5px"
                />
                <AnimatedPlaceholder
                  height="20px"
                  width="500px"
                  borderRadius="5px"
                />
                <AnimatedPlaceholder
                  height="20px"
                  width="500px"
                  borderRadius="5px"
                />
              </div>
              <AnimatedPlaceholder
                height="20px"
                width="80px"
                borderRadius="5px"
              />
              <AnimatedPlaceholder
                height="20px"
                width="500px"
                borderRadius="5px"
              />
            </div>
            <div
              class="d-flex flex-column gap-3 align-items-center justify-content-center"
            >
              <AnimatedPlaceholder
                height="20px"
                width="100px"
                borderRadius="5px"
              />
              <AnimatedPlaceholder
                height="20px"
                width="100px"
                borderRadius="5px"
              />
              <AnimatedPlaceholder
                height="20px"
                width="100px"
                borderRadius="5px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedPlaceholder from "../animations/AnimatedPlaceholder.vue";

export default {
  name: "CourseDetailSkeltons",
  components: { AnimatedPlaceholder },
};
</script>

<style scoped>
.coursePath-placeholder {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
</style>
