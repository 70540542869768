<template>
  <div class="container-fluid main-wrapper" v-if="course">
    <div class="row pt-3">
      <div class="col-12 d-flex">
        <router-link :to="{ name: 'courses', query: { cbjwt: cupajwt } }" class="courseBreadcrumb" @click="navigateToCoursePage"
          >Courses <i class="bi bi-chevron-right" style="font-size: x-small"></i
        ></router-link>
        <span class="ml-2 courseNameBrd" @click="refreshPage">{{
          `${truncatedCourseName|| "Cambridge Course"}`
        }}</span>
      </div>
    </div>
    <div class="row">
      <div
        class="col-2 order-1 order-md-1 d-flex align-items-center book-cover-img"
      >
        <img
          :src="`https://qa4.cogbooks.com/cogContent/newui/content/course/coverImage/course_${course.id}.jpg`"
          alt="Book cover"
          width="66"
          height="66"
          @error="handleImageError"
        />
      </div>
      <div
        class="col-10 order-2 order-md-2 d-flex align-items-center courseName"
      >
        <h2 class="mb-1">
          {{
            `${course.courseName || "Cambridge Course"}`
          }}
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="text-justify introText">
          {{ truncatedCourseIntro }}
        </p>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 d-flex align-items-center"
        v-if="truncatedCourseIntro && truncatedCourseIntro.length > 0"
      >
        <span>
          <a href="#" class="readMore" @click.prevent="openPopupModal"
            >Read more
            <i
              class="bi bi-chevron-right"
              style="font-size: small; -webkit-text-stroke: 1px"
            ></i>
          </a>
        </span>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12 d-flex gap-3 align-items-center estimatedTimeAndTopics"
      >
        <div class="d-flex gap-3 align-items-center">
          <img
            class="topicImg"
            src="../../../public/images/topics-icon.svg"
            height="20"
            width="17"
            alt=""
          />
          <span>{{ course.topicCounts }} topics</span>
        </div>
        <span class="card-details-span">|</span>
        <div class="d-flex gap-3">
          <i class="clockIcon bi bi-clock"></i> {{ addHoursandMin(course) }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex gap-3 align-items-center">
        <div class="progress" style="width: 90%; height: 10px">
          <div
            class="progress-bar card-progress-bar"
            role="progressbar"
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="{ '--progress-width': animatedWidth }"
          ></div>
        </div>
        <span class="progressSpan"
          >{{ animatedPercentage }}%</span
        >
      </div>
    </div>
    <div class="row pb-5">
      <div class="col-12 d-flex">
        <button class="btn btn-success mt-4 btncls" @click="courseNavigation">
          <p class="start_Resume">{{ courseDetails.courseStatus }}</p>
          Learning
        </button>
      </div>
    </div>
    <ModalforCoureseDetail
      :course="course"
      :courseDetails="courseDetails"
      :show="showModal"
      @close="closePopupModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModalforCoureseDetail from "./CourseDetailModal.vue";
import { DEFAULT_IMAGE } from "@/config/config";
import Cookies from "js-cookie";
export default {
  name: "NewCourseDetail",
  components: {
    ModalforCoureseDetail,
  },
  data() {
    return {
      showModal: false,
      animatedWidth: "0%",
      animatedPercentage: 0,
      cupajwt:'',
    };
  },
  computed: {
    truncatedCourseIntro() {
      const maxLength = 200;
      const pText = unescape(this.decodeHTMLEntities(this.courseDetails.courseIntro));
      return this.truncateText(maxLength, pText);
    },
    truncatedCourseName(){
      const mLength = 36;
      const nText = this.course.courseName;
      console.log(nText);
      return this.truncateText(mLength, nText);
    },
    courseDetails() {
      return this.$store.getters.getCourseDetails;
    },
    course() {
      const courseId = this.$route.params.id;
      return this.courseListing.find((course) => course.id === courseId);
    },
    courseListing() {
      return this.$store.getters.getCourseListing;
    },
    ...mapState(["courseListing", "courseDetails"]),
  },
  methods: {
    openPopupModal(){
      this.showModal = true;
      document.body.style.overflow = 'hidden';
    },
    closePopupModal(){
      this.showModal = false;
      document.body.style.overflow = '';
    },
    decodeHTMLEntities(text) {
      const parser = new DOMParser();
      const decodedHTML = parser.parseFromString(text, 'text/html').body.textContent;
      return decodedHTML;
    },
    // addHoursandMin(course) {
    //   var courseTime = this.course.courseEstimationTime;
    //   const [hoursStr, minutesStr] = courseTime.split(" ");
    //   const hours = parseInt(hoursStr);
    //   const minutes = parseInt(minutesStr);
    //   const outputTime = `${hours}h ${minutes}min`;
    //   console.log(outputTime);
    //   return outputTime;
    // },
    addHoursandMin(course) {
      var courseTime = this.course.courseEstimationTime.trim(); 
      let hours = 0;
      let minutes = 0;
      
      if (courseTime.includes('h')) {
        const [hoursStr, minutesStr] = courseTime.split(" ");
        hours = parseInt(hoursStr) || 0;
        minutes = parseInt(minutesStr) || 0;
      } else {
        minutes = parseInt(courseTime) || 0;
      }
      const totalMinutes = hours * 60 + minutes;
      hours = Math.floor(totalMinutes / 60); 
      minutes = totalMinutes % 60;
      
      let outputTime;
      if (hours === 0) {
        outputTime = `${minutes}min`;
      } else {
        outputTime = `${hours}h ${minutes}min`;
      }
      console.log(outputTime);
      return outputTime;
    },
    animateProgress() {
      let currentPercentage = 0;
      const targetPercentage = Math.round(this.course.courseCompletionPercantage);
      const interval = setInterval(() => {
        if (currentPercentage >= targetPercentage) {
          clearInterval(interval);
          return;
        }
        currentPercentage++;
        this.animatedWidth = currentPercentage + "%";
        this.animatedPercentage = currentPercentage;
      }, 20); 
    },
    handleImageError(event) {
      event.target.src = DEFAULT_IMAGE; 
    },
    refreshPage() {
      location.reload();
    },
    courseNavigation() {
      console.log(
        "-----Function Calling on click of button" +
          Number(
            this.courseDetails.moduleIDs
              .split(",")[0]
              .slice(2, this.courseDetails.moduleIDs.split(",")[0].length - 1)
          )
      );
      if (this.courseDetails.courseStatus == "RESUME") {
        window.location.href =
          "https://qa4.cogbooks.com/communicog/handler?action=activityBranding&COURSEID=" +
          this.course.id;
      } else {
        window.location.href =
          "https://qa4.cogbooks.com/communicog/handler?action=sectionbranding&COURSEID=" +
          this.course.id +
          "&SECTIONID=" +
          Number(
            this.courseDetails.moduleIDs
              .split(",")[0]
              .slice(2, this.courseDetails.moduleIDs.split(",")[0].length - 1)
          );
      }
    },
    navigateToCoursePage() {
      this.$router.push({ name: "courses", query:{cbjwt:`${this.cupajwt}`} });
    },
    truncateText(maxLength, text) {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.slice(0, maxLength) + "...";
      }
    },
    ...mapActions(["fetchCourseListing", "fetchCourseDetails"]),
  },

  mounted() {
    let titleTag = document.getElementsByTagName("title")[0];
    // titleTag.innerHTML = 'Cambridge course | ' + this.course.courseName + ' - Details';
    let courseNameEncoded = unescape(this.course.courseName);
    titleTag.innerHTML = "Cambridge course | "+ courseNameEncoded + ' - Details';
    this.animateProgress();
    let objid = this.$route.params.id;
    console.log("Inside CouseListing", objid);
    let cbjwt = Cookies.get("cbjwt");
    if (cbjwt) {
      console.log(" cbjwt ------------- " + cbjwt);
      var payload = cbjwt.split(".")[1];
      console.log(" payload ----------- " + payload);
      var decodePayLoad = window.atob(payload);
      console.log(" decodePayLoad ---------- " + decodePayLoad);
      var payloadJSON = JSON.parse(decodePayLoad);
      console.log(" payloadJSON ---------- " + payloadJSON);
      var uid = payloadJSON.uid;
      this.cupajwt = cbjwt;
      console.log(" payloadJSON ---------- " + uid);
      document.cookie = "cbjwt=" + cbjwt;
    }
    this.fetchCourseDetails({
      objID: objid,
      userID: uid,
    }).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.main-wrapper {
  background-color: #f5f7fc;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.courseBreadcrumb {
  font: normal normal 600 16px/28px "Source Sans 3";
  letter-spacing: 0px;
  color: #4756d3;
  text-decoration: none;
  cursor: pointer;
}
.bi-chevron-right {
  color: #4756d3;
}
.courseNameBrd {
  font: normal normal normal 16px/28px "Source Sans 3";
  letter-spacing: 0px;
  color: #4756d3;
  cursor: pointer;
}
.ml-2 {
  margin-left: 0.5rem;
}
.courseName {
  padding-left: 0;
}
.book-cover-img {
  padding-right: 0;
}
.book-cover-img img {
  border-radius: 5px;
}
.introText {
  font: normal normal normal 16px/24px "Source Sans 3";
  letter-spacing: 0px;
  color: #424242;
  margin: 0;
}
.card-details-span {
  color: #e0e0e0;
}
.estimatedTimeAndTopics {
  font: normal normal normal 16px/24px "Source Sans 3";
  letter-spacing: 1px;
  color: #424242;
}
.readMore {
  font: normal normal bold 16px/28px "Source Sans 3";
  letter-spacing: 0px;
  color: #4756d3;
  text-decoration: none;
}
.clockIcon{
  -webkit-text-stroke: 1px;
  font-size: medium;
}
.readMore:hover {
  text-decoration: underline;
}
@keyframes progressBarAnimation {
  from {
    width: 0%;
  }
  to {
    width: var(--progress-width);/* Or your desired final width */
  }
}

/* Animation for number */
@keyframes numberAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.card-progress-bar {
  background-color: #00bdb6;
  border-radius: 10px;
  animation: progressBarAnimation 2s ease-in-out forwards;
}
.start_Resume {
  text-transform: lowercase;
  margin: 0;
  display: inline-block;
  color: #ffffff;
  font: normal normal 600 16px/22px "Source Sans 3";
  letter-spacing: 0px;
  color: #ffffff;
}
.start_Resume::first-letter {
  text-transform: uppercase;
}
.progressSpan {
  font: normal normal bold 20px/32px "Source Sans 3";
  color: #212121;
  animation: numberAnimation 2s ease-in-out forwards;
}
.btncls {
  background: #5465f8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 44px;
  margin: 0px;
  margin-top: 0px !important;
  font: normal normal 600 16px/22px "Source Sans 3";
  letter-spacing: 0px;
  color: #ffffff;
}
.btncls:hover {
  background: #4756d3 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
}
.button:focus {
  outline: none;
}
@media only screen and (max-width: 600px) {
  .courseName {
    padding-left: 30px;
  }
  .progress {
    width: 82% !important;
  }
}
</style>
