import gql from 'graphql-tag';

export const COURSE_LISTING_QUERY = gql`
    query CourseListing($uid:String!) {
      courseListing(uid:$uid){
        id
        courseName
        topicCounts
        courseCompletionPercantage
        courseEstimationTime
        instituteName
        courseAccess
      }
  }`
  export const COURSE_DETAILS_QUERY = gql`
  query CourseDetails($objID:String!,$uid:String!) {
    courseDetails(objID:$objID,uid:$uid){
      courseIntro
      courseStatus
      moduleIDs
    }
}`
export const SEARCH_DETAILS_QUERY = gql`
  query CourseSearchInfo($objID:String!) {
    courseSearchInfo(objID:$objID){
      id
      Desc
      Name
    }
}`
 export const COURSE_OUTLINE_DETAILS_QUERY = gql`
 query CourseOutlineDetails($objID:String!,$uid: String!) {
   courseOutlineDetails(objID:$objID,uid: $uid) {
     secID
     secStatus
     color
     secOrder
     estTime
     name
     secIntro
     sectionNavigationCriteriaSequence
     sectionNavigationCriteria
     sectionActivityNavigationCriteriaSequence
     objID
     objType
     useCourseConfigSetting
     sectionActivityNavigationCriteria
     subSectionData {
       subSecID
       subSecEst
       subSecName
       subSecOrder
       subSecColor
       subSectionNavigationCriteriaSequence
       subSectionNavigationCriteria
       subSectionActivityNavigationCriteriaSequence
       objID
       objType
       useCourseConfigSetting
       sectionActivityNavigationCriteria
     }
   }
 }
`