<template>
  <div class="float-start w-100 h-100 d-flex courses-page-wrapper">
    <div class="courseListing d-flex align-items-start flex-column w-100">
      <div tabindex="1" aria-label="Your courses" id="main-content" class="courselisting-heading">
        <h4 style="margin-bottom: 0">Your Courses</h4>
      </div>
      <div class="courseCards float-start">
        <CBCourseListingCard
          v-for="course in courseListing"
          :class="{ 'disabled-card': !(course.courseAccess) }"
          :key="course.id"
          :courseID="course.id"
          :title="`${course.courseName || 'Cambridge Course'}`"
          :topics="course.topicCounts"
          :duration="course.courseEstimationTime"
          :progress="Math.round(course.courseCompletionPercantage)"
          @click="viewCourseDetails(course)"
          v-on:keyup.enter="viewCourseDetails(course)"
          :tabindex="course.courseAccess ? 1 : -1"
          :aria-label="`${course.courseName} consists of ${
            course.topicCounts
          } topics with an estimated completion time of ${addHoursandMin(
            course
          )}. ${Math.round(
            course.courseCompletionPercantage
          )} % of the course is completed. Press enter to view the overview of the course`"
        >
        </CBCourseListingCard>
      </div>
    </div>
  </div>
</template>

<script>
import CBCourseListingCard from "@/components/courseListing/CBCourseListingCard.vue";
import Cookies from "js-cookie";

export default {
  components: {
    CBCourseListingCard,
  },
  data() {
    return {
      cbjwt: "", // Initialize cbjwt as a data property
    };
  },
  computed: {
    courseListing() {
      console.log(this.$store.getters.getCourseListing);
      return this.$store.getters.getCourseListing;
    },
  },
  mounted() {
    let titleTag = document.getElementsByTagName("title")[0];
    titleTag.innerHTML = "Your Courses";
    this.cbjwt = Cookies.get("cbjwt");
    this.$store.commit("setSearchBarVisible", false);
    this.$store.dispatch("userNameAction"),
      this.$store.dispatch("userEmailAction");
  },
  methods: {
    addHoursandMin(course) {
      var courseTime = course.courseEstimationTime;
      const [hoursStr, minutesStr] = courseTime.split(" ");
      const hours = parseInt(hoursStr);
      const minutes = parseInt(minutesStr);

      // Construct the output string
      const outputTime = `${hours} hours ${minutes} minutes`;
      console.log(outputTime);
      return outputTime;
    },
    viewCourseDetails(course) {
      this.$router.push({
        name: "course-details",
        params: { id: course.id },
        query: { cbjwt: this.cbjwt },
      });
      window.scrollTo(0, 0);
      console.log("Line 84 from CourseListing", this.cbjwt);
    },
  },
  created() {
    // Assuming you want to fetch courseListing when the component is created
    //this.$store.dispatch('fetchCourseListing', { userID:  });
  },
};
</script>

<style lang="scss" scoped>
.courseListing {
  float: left;
  padding: 36px;

  .courseCards {
    display: flex;
    gap: 24px;
  }

  .courseCards a {
    flex-basis: 31.5%;
  }
}

.courses-page-wrapper {
  background-color: #f7f7f7;
}

.courselisting-heading {
  margin-bottom: 24px;
}

@media only screen and (max-width: 600px) {
  .courseListing .courseCards a {
    min-width: 95vw;
    width: 100%;
    flex-basis: auto;
  }

  .courseListing .courseCards {
    flex-wrap: wrap;
  }

  .courses-page-wrapper {
    padding: 0px 16px 32px 16px;
  }
  .courseListing {
    padding: 0 !important;
  }

  .courselisting-heading[data-v-2ecf4264] {
    margin-top: 24px;
    margin-bottom: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .courseListing {
    max-width: calc(100vw - 200px);
  }

  .courseCards {
    width: 100%;
    flex-wrap: wrap;
  }
}
.disabled-card{
  opacity: 0.5;
  pointer-events: none;
}
</style>
