<template>
  <div v-if="isMobile" class="mobile-page">
    <div v-if="loading" class="skeleton-loader">
      <CourseDetailSkeltonForMobile />
    </div>
    <div v-else>
      <NewCourseDetail />
    </div>
  </div>
  <div v-else>
    <div v-if="loading" class="skeleton-loader">
      <CourseDetailSkeltons />
    </div>
    <div v-else class="float-start w-100 p-4 courses-detail-page-wrapper">
      <div class="details-wrapper">
        <div class="row pb-4">
          <div class="coursePath d-flex gap-3">
            <span
              tabindex="1"
              role="application"
              aria-label="Breadcrumb button 1, Courses button, Press enter to go back to courses page"
              v-on:keyup.enter="navigateToCoursePage"
              class="goToCoursepageClass"
              @click="navigateToCoursePage"
              >Courses</span
            >
            <span class="middleIconforCourse"
              ><i class="bi bi-chevron-right" style="font-size: x-small"></i
            ></span>
            <span
              tabindex="1"
              role="application"
              :aria-label="`Breadcrumb button2, ${course.courseName} button, press enter to refresh page.`"
              class="course-name clickable"
              v-if="course"
              @click="refreshPage"
              @keyup.enter="refreshPage"
            >
              {{ `${course.courseName || "Cambridge Course"}` }}
            </span>
          </div>
        </div>
        <div class="row course-details-intro">
          <div class="course-details float-start d-flex gap-4">
            <div class="course-image">
              <img
                class="crsImg"
                :src="`https://qa4.cogbooks.com/cogContent/newui/content/course/coverImage/course_${course.id}.jpg`"
                @error="handleImageError"
                alt=""
              />
            </div>
            <div class="course-intro d-flex flex-column" v-if="course">
              <div
                class="course-introduction-div"
                id="main-content"
                tabindex="1"
                :aria-label="`Course Title - ${
                  course.courseName
                }, Course description - ${
                  truncatedCourseIntro || 'No Course description'
                }`"
              >
                <h2 class="course-name111">
                  {{ truncatedCourseName || "Cambridge Course" }}
                </h2>
                <p class="course-intro-details">{{ truncatedCourseIntro }}</p>
              </div>

              <span
                v-if="truncatedCourseIntro && truncatedCourseIntro.length > 0"
                ><a
                  tabindex="1"
                  aria-label="Read more button, Press enter to read more"
                  v-on:keyup.enter="openPopupModal"
                  href="#"
                  class="readMore"
                  @click.prevent="openPopupModal"
                  >Read more <i class="bi bi-chevron-right chevronRight"></i></a
              ></span>
              <div
                v-if="showModal"
                class="modalpopup"
                ref="modalPopup"
                aria-modal="true"
              >
                <ModalforCoureseDetail
                  tabindex="1"
                  :course="course"
                  :courseDetails="courseDetails"
                  :show="showModal"
                  @close="closePopupModal"
                  role="dialog"
                />
              </div>
              <div
                tabindex="1"
                role="application"
                :aria-label="`Course progress: ${Math.round(
                  course.courseCompletionPercantage
                )} % of the course is completed`"
                class="card-progress-div"
              >
                <div class="card-progress">
                  <div
                    class="card-progress-bar"
                    :style="{ '--progress-width': animatedWidth }"
                  ></div>
                </div>
                <span class="progressSpan">{{ animatedPercentage }}%</span>
              </div>
            </div>
            <div
              class="course-estimation d-flex gap-2 flex-column"
              v-if="course"
            >
              <div
                class="timeAndTopics"
                tabindex="1"
                role="application"
                :aria-label="`Course consists of ${
                  course.topicCounts
                } topics with an estimated completion time of ${addHoursandMin(
                  course
                )}.`"
              >
                <div class="d-flex gap-2 align-items-center">
                  <img
                    class="topicImg"
                    src="../../../public/images/topics-icon.svg"
                    height="20"
                    width="17"
                    alt=""
                  />
                  <span>{{ course.topicCounts }} topics</span>
                </div>
                <div
                  class="d-flex gap-2"
                  :aria-label="`${addHoursandMin(course)}`"
                >
                  <i class="clockIcon bi bi-clock"></i>
                  {{ addHoursandMinTime(course) }}
                </div>
              </div>
              <button
                tabindex="1"
                :aria-label="`${courseDetails.courseStatus} learning button, Press enter to ${courseDetails.courseStatus} learning`"
                class="btn btn-success mt-4 btncls"
                v-on:keyup.enter="courseNavigation"
                @click="courseNavigation"
              >
                <p class="start_Resume">{{ courseDetails.courseStatus }}</p>
                Learning
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="float-start w-100 p-4 courses-detail-page-wrapper2">
    <div class="details-wrapper2">
      <CoursePagePagination />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Cookies from "js-cookie";
import CoursePagePagination from "./Pagination.vue";
import ModalforCoureseDetail from "./CourseDetailModal.vue";
import Encoder from "/src/constant/encoder.js";
import CourseDetailSkeltons from "../skeltons/CourseDetailSkeltons.vue";
import PaginationForMobile from "../mobileComponents/PaginationForMobile.vue";
import DraggableTabs from "../draggableTabs/DraggableTabs.vue";
import NewCourseDetail from "./MobileCourseDetail.vue";
import { DEFAULT_IMAGE } from "@/config/config";
import CourseDetailSkeltonForMobile from "../skeltons/CourseDetailSkeltonForMobile.vue";
export default {
  name: "courseDetailsPage",
  components: {
    CoursePagePagination,
    ModalforCoureseDetail,
    CourseDetailSkeltons,
    NewCourseDetail,
    CourseDetailSkeltonForMobile,
    DraggableTabs,
  },
  data() {
    return {
      loading: true,
      showModal: false,
      isMobile: false,
      currentCourseId: "",
      animatedWidth: "0%",
      animatedPercentage: 0,
      cupajwt: "",
    };
  },
  computed: {
    truncatedCourseIntro() {
      let maxLength = 200;
      if (window.innerWidth >= 1440) {
        maxLength = 300;
      }
      const pText = unescape(
        this.decodeHTMLEntities(this.courseDetails.courseIntro)
      );
      return this.truncateText(maxLength, pText);
    },
    truncatedCourseName() {
      let mLength = 26;
      if (window.innerWidth >= 1440) {
        mLength = 40;
      }
      const nText = this.course.courseName;
      console.log(nText);
      return this.truncateText(mLength, nText);
    },
    courseDetails() {
      return this.$store.getters.getCourseDetails;
    },
    course() {
      let courseId ;
      if(this.$route.params.id){
        courseId = this.$route.params.id;
      }else{
        courseId = getParameterByName("COURSEID");
      }
      
      return this.courseListing.find((course) => course.id === courseId);
    },
    courseListing() {
      console.log(this.$store.getters.getCourseListing);
      return this.$store.getters.getCourseListing;
    },
    ...mapState({
      // 'courseOutlineInfo' will be accessible in this component as this.courseOutlineInfo
      courseOutlineInfo: (state) => state.courseOutlineInfo,
      courseListing: (state) => state.courseListing,
      courseDetails: (state) => state.courseDetails,
      searchDetails: (state) => state.searchDetails,
    }),
  },
  methods: {
    constructImgUrl() {
      // Assuming your image URL format is something like this
      console.log(this.currentCourseId);
      return `https://qa4.cogbooks.com/cogContent/newui/content/course/coverImage/course_${this.currentCourseId}.jpg`;
    },
    addHoursandMinTime(course) {
      var courseTime = this.course.courseEstimationTime.trim();
      let hours = 0;
      let minutes = 0;

      if (courseTime.includes("h")) {
        const [hoursStr, minutesStr] = courseTime.split(" ");
        hours = parseInt(hoursStr) || 0;
        minutes = parseInt(minutesStr) || 0;
      } else {
        minutes = parseInt(courseTime) || 0;
      }
      const totalMinutes = hours * 60 + minutes;
      hours = Math.floor(totalMinutes / 60);
      minutes = totalMinutes % 60;

      let outputTime;
      if (hours === 0) {
        outputTime = `${minutes}min`;
      } else {
        outputTime = `${hours}h ${minutes}min`;
      }
      console.log(outputTime);
      return outputTime;
    },
    animateProgress(course) {
      let currentPercentage = 0;
      const targetPercentage = Math.round(
        this.course.courseCompletionPercantage
      );
      const interval = setInterval(() => {
        if (currentPercentage >= targetPercentage) {
          clearInterval(interval);
          return;
        }
        currentPercentage++;
        this.animatedWidth = currentPercentage + "%";
        this.animatedPercentage = currentPercentage;
      }, 50);
    },
    handleKey(e) {
      if (e.keyCode === 9) {
        const focusable = document.querySelectorAll(
          ".course-title, .close, .main-content"
        );
        if (focusable.length) {
          let first = focusable[0];
          let last = focusable[focusable.length - 1];
          let shift = e.shiftKey;
          if (shift) {
            if (e.target === first) {
              last.focus();
              e.preventDefault();
            }
          } else {
            if (e.target === last) {
              first.focus();
              e.preventDefault();
            }
          }
        }
      }
    },
    openPopupModal() {
      this.showModal = true;
      document.body.style.overflow = "hidden";
      this.$nextTick(() => {
        this.$refs.modalPopup.focus();
      });
      window.addEventListener("keydown", this.handleKey);
    },
    closePopupModal() {
      this.showModal = false;
      document.body.style.overflow = "";
      window.removeEventListener("keydown", this.handleKey);
    },
    addHoursandMin(course) {
      var courseTime = this.course.courseEstimationTime;
      const [hoursStr, minutesStr] = courseTime.split(" ");
      const hours = parseInt(hoursStr);
      const minutes = parseInt(minutesStr);
      const outputTime = `${hours} hours ${minutes} minutes`;
      console.log(outputTime);
      return outputTime;
    },
    refreshPage() {
      location.reload();
    },
    courseNavigation() {
      console.log(
        "-----Funstion Calling on click of button" +
          Number(
            this.courseDetails.moduleIDs
              .split(",")[0]
              .slice(2, this.courseDetails.moduleIDs.split(",")[0].length - 1)
          )
      );
      if (this.courseDetails.courseStatus == "RESUME") {
        window.location.href =
          "https://qa4.cogbooks.com/communicog/handler?action=activityBranding&COURSEID=" +
          this.course.id;
      } else {
        window.location.href =
          "https://qa4.cogbooks.com/communicog/handler?action=sectionbranding&COURSEID=" +
          this.course.id +
          "&SECTIONID=" +
          Number(
            this.courseDetails.moduleIDs
              .split(",")[0]
              .slice(2, this.courseDetails.moduleIDs.split(",")[0].length - 1)
          );
      }
    },
    handleImageError(event) {
      event.target.src = DEFAULT_IMAGE;
    },
    navigateToCoursePage() {
      this.$router.push({
        name: "courses",
        query: { cbjwt: `${this.cupajwt}` },
      });
    },
    truncateText(maxLength, text) {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.slice(0, maxLength) + "...";
      }
    },
    decodeHTMLEntities(text) {
      const parser = new DOMParser();
      const decodedHTML = parser.parseFromString(text, "text/html").body
        .textContent;
      return decodedHTML;
    },
    ...mapActions([
      "fetchCourseListing",
      "fetchCourseDetails",
      "fetchSearchDetails",
    ]),
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768;
    },
  },
  created() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  watch: {
    "$store.state.objID"(newObjId) {
      this.currentCourseId = newObjId;
    },
  },
  mounted() {
    var cName = this.courseListing
    this.animateProgress(cName);
    console.log(cName);
    $(
      window.document.getElementsByClassName("coursesbutton")[0].children[0]
    ).addClass("router-link-active router-link-exact-active");
    this.$store.commit("setSearchBarVisible", true);
    let objid = this.$route.params.id;
    this.$store.commit("setcourseid", objid);
    let titleTag = document.getElementsByTagName("title")[0];
    let courseNameEncoded = unescape(this.course.courseName);
    console.log(courseNameEncoded)
    titleTag.innerHTML = courseNameEncoded + " - Details";
    var cbjwt = getParameterByName("cbjwt");

    if (cbjwt) {
      console.log(" cbjwt ------------- " + cbjwt);
      var payload = cbjwt.split(".")[1];
      console.log(" payload ----------- " + payload);
      var decodePayLoad = window.atob(payload);
      console.log(" decodePayLoad ---------- " + decodePayLoad);
      var payloadJSON = JSON.parse(decodePayLoad);
      console.log(" payloadJSON ---------- " + payloadJSON);
      var classId = payloadJSON.course;
      var uid = payloadJSON.uid;
      console.log("class id --------------- " + classId);
      console.log("user id ---------------- " + uid);
      this.cupajwt = cbjwt;
      document.cookie = "cbjwt=" + cbjwt;
    }
    this.fetchCourseDetails({
      objID: objid,
      userID: uid,
    }).then(() => {
      this.loading = false;
    });
    this.fetchSearchDetails({
      objID: objid,
    });
  },
};
</script>

<style scoped>
@keyframes progressBarAnimation {
  from {
    width: 0%;
  }
  to {
    width: var(--progress-width);
  }
}

@keyframes numberAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.courses-detail-page-wrapper {
  background-color: #f5f7fc;
}
.timeAndTopics {
  font: normal normal normal 18px/24px "Source Sans 3";
  display: flex;
  flex-direction: column;
  letter-spacing: 0px;
  color: #424242;
  gap: 16px;
  padding-top: 20px;
}
.courses-detail-page-wrapper2 {
  background-color: #f7f7f7;
}

.courses-detail-page-wrapper,
.courses-detail-page-wrapper2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
}
.course-name111 {
  flex: 1 1 0;
}
.modalpopup {
  flex: 1 1 0;
}
.course-introduction-div {
  flex: 2 1 0;
}
.clockIcon {
  -webkit-text-stroke: 1px;
  font-size: large;
}
.start_Resume {
  text-transform: lowercase;
  margin: 0;
  display: inline-block;
  color: #ffffff;
  font: normal normal 600 16px/22px "Source Sans 3";
  letter-spacing: 0px;
  color: #ffffff;
}
.start_Resume::first-letter {
  text-transform: uppercase;
}
@media only screen and (min-width: 1024px) {
  .details-wrapper,
  .details-wrapper2 {
    max-width: calc(100vw - 200px);
    padding-left: 64px !important;
  }
}
.bi-chevron-right {
  width: 4px;
  height: 7px;
}
.coursePath {
  color: #4756d3;
  font: normal normal 600 16px/28px "Source Sans 3";
  letter-spacing: 0px;
}
.goToCoursepageClass {
  cursor: pointer;
}
.course-name {
  font: normal normal normal 16px/28px "Source Sans 3";
  letter-spacing: 0px;
  cursor: pointer;
}
.course-details {
  height: 200px;
}
.course-details-intro {
  height: 200px;
  width: 100%;
}
.course-intro {
  flex: 5;
}
.course-introduction-div {
  flex: 1;
}
h2 .course-name111 {
  font: normal normal bold 32px/32px Lato !important;
  letter-spacing: 0px;
  color: #212121;
}
p {
  font: normal normal normal 16px/24px "Source Sans 3";
  letter-spacing: 0px;
  color: #424242;
}
.course-image {
  max-width: 224px;
  /* background-color: #0078d7; */
  border-radius: 8px;
  background-image: url("https://author.cogbooks.com/cogContent/newui/content/course/header.jpg");
}
.readMore {
  font: normal normal 600 16px/22px "Source Sans 3";
  letter-spacing: 0px;
  color: #4756d3;
  text-decoration: none;
  flex: 1 1 0;
}
.readMore:hover {
  text-decoration: underline;
}
.chevronRight {
  -webkit-text-stroke: 1px;
  font-size: small;
}
.crsImg {
  border-radius: 8px;
  height: 200px;
  width: 224px;
  border: 1px solid #e0e0e0;
}
.topicImg {
  width: 20px;
  height: 17px;
  margin-top: 3px;
}
.progressSpan {
  font: normal normal bold 24px/32px "Source Sans 3";
  color: #212121;
  animation: numberAnimation 2s ease-in-out forwards;
}
.course-estimation {
  flex: 1;
  padding-left: 20px;
  border-left: 1px solid #e0e0e0;
  justify-content: center;
  align-items: flex-start;
}
.rightSideIcons {
  border-left: 1px solid #e0e0e0;
  padding: 0 15px 0 20px;
}
.btncls {
  background: #5465f8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  border: none;
  width: 150px;
  font: normal normal 600 16px/22px "Source Sans 3";
  letter-spacing: 0px;
  color: #ffffff;
  height: 44px;
}
.btn-success {
  background-color: #5465f8 !important;
}
.btn-success:active {
  background-color: #4756d3 !important;
}
.btn-success:focus {
  border: #4756d3 !important;
  background: #4756d3 !important;
  box-shadow: 0 0 0 0.25rem #4756d3 !important;
}
.btncls:hover {
  background: #4756d3 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  border: none;
}
.button:focus {
  outline: none;
}
.card-progress-div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex: 1 1 0;
}
.card-progress {
  height: 10px;
  background-color: #cfcfcf;
  border-radius: 5px;
  overflow: hidden;
  flex: 4;
}
.card-progress-bar {
  height: 100%;
  background-color: #00bdb6;
  border-radius: 10px;
  animation: progressBarAnimation 2s ease-in-out forwards;
}
.pagination-details-wrapper {
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .course-intro:first-child {
    flex: 1 1 80%;
  }
  .course-image {
    height: 66px;
    width: 66px;
  }
  .crsImg {
    height: 66px;
    width: 66px;
  }
  .detail-page-wrapper2 {
    padding-left: 16px !important;
    max-width: 90vw !important;
  }
  .details-wrapper2 {
    max-width: 90vw !important;
  }
}
</style>
