<template>
    <div>
        <div class="gradeinfo">Track your grades and assignment progress for this course.</div>
        <iframe class="iFrame":src="jspUrl" width="100%" height="600px" frameborder="0"></iframe>
    </div>
  </template>
  
  <script>
  import { VUE_END_POINT } from "../../config/config";
  
  export default {
    name:"Grades",
    mounted(){
        var cbjwt = getParameterByName("cbjwt");
        this.JWTToken = cbjwt;
        this.objid = getParameterByName("SECID");
        this.jspUrl = VUE_END_POINT+'/learner/course/gradecenter/v1/#/?cbjwt='+this.JWTToken+'&objid='+this.objid+'&objtype=SECTIONTYPE&NEXTURL='+encodeURI(window.location.href)+'&Page=LD';
    },
    setup () {
        
  
        return {}
    },
    data(){
        return{
            JWTToken: '',
            jspUrl: '',
            objid: '',
        }
    },
  
  }
  </script>
  
  <style lang="scss" scoped> 
  .gradeinfo{
    width: 390px;
    height: 20px;
    text-align: left;
    font: normal normal normal 16px / 24px Source Sans Pro;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-top: 23px;
    margin-bottom: 48px;
  }
  </style>
  