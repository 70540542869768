import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CourseListingView from "../views/CourseListingView.vue";
import CourseDetail from "@/components/courseDetail/CourseDetail.vue";
import CourseOutline from "../components/courseOutline/CourseOutline.vue";
import Grades from "../components/grades/Grades.vue";
import HighlightsAndNotes from "../components/highlightsAndNotes/HighlightsAndNotes.vue";
import Assignments from "../components/assignments/Assignments.vue";
import Cookies from "js-cookie";

const cbjwt  = Cookies.get("cbjwt");

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/courses/",
    name: "courses",
    component: CourseListingView,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/CourseListingView.vue"),
  },
  {
    path: "/courses/:id/",
    name: "course-details",
    component: CourseDetail,

  },
  {
    path: "/:id/grades",
    name: "Grades",
    component: Grades,

  },
  {
    path: "/:id/highlightandnotes",
    name: "HighlightsAndNotes",
    component: HighlightsAndNotes,

  },
  {
    path: "/:id/assignments",
    name: "Assignments",
    component: Assignments,

  },
  {
    path: "/:id/courseoutline",
    name: "CourseOutline",
    component: CourseOutline,

  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
