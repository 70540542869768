<template>
  <footer class="bg-white float-start w-100" >
    <div class="w-100">
      <div class="flex flex-wrap items-center justify-between">
        <div class="w-100 py-4 float-start">
          <div class="d-inline-block text-md-end d-flex justify-content-end px-4 footer-top-sec">
            <span class="d-flex align-items-center copyClass"
              aria-label="Powered By CogBooks"
              tabindex="1"
            >Powered By CogBooks</span>
            <div class="verticalLine"></div>
            <img
              src="../../assets/img/Cambridge Logo.svg"
              alt="Company Logo"
              class="img-fluid"
              width="189px"
              height="32px"
            />
          </div>
        </div>
        <div class="w-100 d-flex bg-black footer-bottom-sec">
          <div class="col-md-10 md:text-left d-flex md:mb-0 footerbtmclas">
            <span class="text-white d-flex align-items-center text-sm m-4 copyrightCambridge"
              tabindex="1"
              aria-label="© 2024 Cambridge University Press & Assessment"
              >© {{ currentYear }} Cambridge University Press & Assessment</span
            >
            <div class="d-flex align-items-center">
              <a
              href="https://www.cambridge.org/accessibility"
              target="_blank"
              tabindex="1"
              class="text-white hover:text-gray-800 font-bold text-sm ml-2"
              >Accessibility</a
            >
            <span class="mx-2 text-white lineCls">|</span>
            <a
              href="https://www.cambridge.org/legal/privacy"
              target="_blank"
              tabindex="1"
              class="text-white hover:text-gray-800 font-bold text-sm"
              >Privacy</a
            >
            <span class="mx-2 text-white lineCls">|</span>
            <a
              href="https://www.cambridge.org/legal/cookies"
              target="_blank"
              tabindex="1"
              class="text-white hover:text-gray-800 font-bold text-sm"
              >Use of Cookies</a
            >
            <span class="mx-2 text-white lastverticleLine lineCls">|</span>
            </div>
            
          <div class="d-flex align-items-center">
            <a
              href="https://www.cambridge.org/legal/website-terms-of-use"
              target="_blank"
              tabindex="1"
              class="text-white hover:text-gray-800 font-bold text-sm"
              >Terms and Conditions</a
            >
            <span class="mx-2 text-white lineCls">|</span>
            <a
              href="https://www.cambridge.org/legal"
              target="_blank"
              tabindex="1"
              class="text-white hover:text-gray-800 font-bold text-sm"
              >Legal</a
            >
          </div>
          </div>
          <div class="col-md-2 md:mb-0 d-flex align-items-center justify-content-end px-4 footerLogo11">
            <a
              href="https://www.linkedin.com/company/cambridge-university-press-and-assessment/"
              target="_blank"
              tabindex="1"
              ><img
                class="footerLogo"
                style="width: 32px; height: 32px"
                aria-label="Linkedin"
                role=""
                src="https://www.cogbooks.com/communicog/newUI/CogWarePurchase/images/FooterLinkedinIcon.svg"
            /></a>
            <a href="https://www.facebook.com/CambPressAssess" target="_blank" tabindex="1">
              <img
                class="footerLogo"
                aria-label="FaceBook"
                role=""
                style="width: 32px; height: 32px; margin-left: 16px"
                src="https://www.cogbooks.com/communicog/newUI/CogWarePurchase/images/FooterFacebookIcon.svg"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped>
.verticalLine {
  border-right: 1px solid #e0e0e0;
  margin-left: 24px;
  margin-right: 24px;
}
.lineCls{
  color: #616161 !important;
}
a {
    text-decoration: none;
    font: normal normal normal 14px/18px "Source Sans 3";
    color: #FFFFFF;
    font-family: "Source Sans 3";
}

a:hover {
    text-decoration: underline;
    color: inherit
}
span{
  font: normal normal normal 14px/18px "Source Sans 3";
  font-family: "Source Sans 3";
}
.copyClass{
  color: #212121;
}

footer {
  border-top: 1px solid #E0E0E0;
  z-index: 99;
  position: relative;
}
.footer-bottom-sec{
  height: 48px;
}
@media only screen and (max-width: 600px) {
  .footer-top-sec{
    flex-direction: column;
    padding-inline: 16px !important;
    gap:16px;
  }
  .verticalLine{
    display: none !important;
  }
  .footer-bottom-sec{
    flex-direction: column;
    padding: 16px;
    height: auto;
  }
  .copyrightCambridge{
    margin: 0 !important;
  }
  .footerLogo11{
    justify-content: flex-start !important;
    padding-inline: 0 !important;
  }
  .footerbtmclas{
    flex-direction: column;
    gap: 16px;
  }
  .footerLogo11{
    padding-top: 16px;
  }
  .lastverticleLine{
    display: none;
  }

}
</style>
