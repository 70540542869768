<template>
  <div
    class="modal-overlay"
    v-if="show"
    @click="closeModalOutside"
    tabindex="-1"
    ref="trapRef"
    role="dialog"
  >
    <div class="modal" ref="modal" @keydown.escape="handleEscape">
      <div class="modal-content">
        <div class="modal-header" ref="modalHeader">
          <h2
            class="course-title"
            ref="modalTitle"
            tabindex="1"
            :aria-label="`Course Title - ${course.courseName}`"
            autofocus
          >
            {{ course.courseName }}
          </h2>
          <span
            tabindex="1"
            v-on:keyup.enter="closeModal"
            role="application"
            aria-label="Cancel button, press enter to close the popup"
            class="close"
            @click="closeModal"
            ><img src="../../assets/img/closeIconForModal.svg" /></span
          >
        </div>
        <div class="scrollable-content" :style="{ marginTop: headerHeight + 'px' }">
        <div
          class="main-content"
          tabindex="1"
          :aria-label="`Course description - ${truncatedCourseIntro}`"
          
        >
          <p v-html="truncatedCourseIntro"></p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalforCoureseDetail",
  props: {
    course: Object,
    courseDetails: Object,
    show: Boolean,
  },
  data() {
    return {
      modalTitle: "Modal Header Text",
      headerHeight: 0,
    };
  },
  computed: {
    truncatedCourseIntro() {
      const pText = unescape(this.courseDetails.courseIntro);
      return pText;
    },
  },
  methods: {
    handleKey(e) {
      if (e.keyCode === 9) {
        const focusable = document.querySelectorAll('.course-title, .close, .main-content');
        if (focusable.length) {
          let first = focusable[0];
          let last = focusable[focusable.length - 1];
          let shift = e.shiftKey;
          if (shift) {
            if (e.target === first) {
              last.focus();
              e.preventDefault();
            }
          } else {
            if (e.target === last) {
              first.focus();
              e.preventDefault();
            }
          }
        }
      }
    },
    closeModal() {
      this.$emit("close");
      window.removeEventListener("keydown", this.handleKey);
    },
    closeModalOutside(event) {
      if (event.target === this.$refs.modal) {
        this.closeModal();
      }
    },
    handleEscape(event) {
      if (event.key === "Escape") {
        this.closeModal();
      }
    },
    updateHeaderHeight() {
      this.headerHeight = this.$refs.modalHeader.clientHeight;
    },
  },
  mounted() {
    this.$el.addEventListener("tab", (event) => {
      event.preventDefault();
    });
    if (this.show) {
      // Set focus to the modal title when the modal is shown
      this.$refs.modalTitle.focus();
      // Disable scrolling on the body
      document.body.style.overflow = "hidden";
      this.updateHeaderHeight();
    }
    window.addEventListener("resize", this.updateHeaderHeight);
  },
  beforeDestroy() {
    // Enable scrolling on the body when the component is destroyed
    document.body.style.overflow = "auto";
    window.removeEventListener("resize", this.updateHeaderHeight);
  },
};
</script>

<style scoped>
/* Add your modal styles here */
.modal-header {
  position: fixed;
  background-color: #f7f7f7;
  width: 45%;
  border-bottom: none !important;
  padding: 32px 0 0 0;
  align-items: baseline;
  margin: 0 !important;

}
/* .main-content {
  height: (80vh);
} */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  border-radius: 6px;
  overflow: hidden;
}

.modal-content {
  background-color: #f7f7f7;
  margin: 15% auto;
  padding: 0px 32px 32px 48px;
  border: 1px solid #888;
  width: 50%;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 6px;
}
.close {
  position: absolute;
  right: -3%;
  top: 2%;
  height: 41px;
  width: 41px;
  color: #212121;
  font-size: 32px;
  font-weight: normal;
  display: flex;
  padding-left: 15px !important;
  padding: 5px;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  .modal-content {
    width: 90%;
    max-height: 80vh;
  }
  .modal-header{
    width: 80%;
    padding-top: 24px;
  }
  .scrollable-content{
    margin-top: 29% !important;
  }
  /* .main-content {
    margin-top: 32% !important;
  } */
  .modal-content {
    padding: 0px 24px 28px 24px;
  }
  .course-title {
    margin-bottom: 12px !important;
  }
  .close{
    top: -5%;
    right: -5%;
  }
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
h2 {
  font: normal normal bold 26px/32px Lato;
  letter-spacing: 0px;
  color: #212121;
}
p {
  font: normal normal normal 16px/24px "Source Sans 3";
  letter-spacing: 0px;
  color: #424242;
}
.scrollable-content {
  max-height: calc(80vh);
  overflow-y: auto;
}
</style>
