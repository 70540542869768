<template>
  <div class="search-wrapper-with-input"
    @click="focusChild"
    @keyup.enter="focusChild"
    aria-labelledby="input-field-label"
  >
    <div
      class="searchInput d-flex"
      id="searchInput"
      @keydown.enter="focusChild"
      aria-labelledby=""
      @blur="handleBlur"
      @click="focusChild"
      @keyup.enter="focusChild"
    >
      <input
        ref="inputRef"
        id="input-field"
        type="text"
        v-model="input"
        @input="sanitizeInput"
        @keyup.enter="redirectToResult(input)"
        placeholder="Search learning activities"
        @focus="handleFocus"
        autocomplete="off"
        @keydown.down="downArrowClick"
        aria-hidden="false"
      />
      <button
        tabindex="1"
        aria-label="Clear search field"
        @keyup.enter="clearSearchBox"
        @keydown.enter="clearSearchBox"
        @click="clearSearchBox"
        class="btn btn-default closeButton"
        id="closeButton"
      >
        <i class="bi bi-x-lg" style="color: #000"></i>
      </button>
      <div
        class="searchIconContainer"
        aria-label="Search your query"
        role="button"
        aria-hidden="true"
        @click="redirectToResult(input)"
        @keyup.enter="redirectToResult(input)"
        ref="searchButtonRef"
      >
        <img
          class="searchIconImg"
          src="../assets/img/search-icon-black.svg"
          alt=""
          @click="redirectToResult(input)"
          @keyup.enter="redirectToResult(input)"
          aria-hidden="false"
        />
      </div>
      <span id="input-field-label" style="display: none;">  Search box, Press enter to search for learning activities. </span>
    </div>
    <div v-if="showResults" class="allListItems" id="allListItems" @keydown.down.prevent="handleDownArrow" @keydown.up.prevent="handleUpArrow">
      <div
        v-if="recentSearches.length > 0 && input.length < 1"
        class="recent-searches-list"
        
      >
        <div class="recent-search" >
          <span tabindex="1" aria-label="Recent Searches" >Recent searches</span>
          <span
            @click="clearRecentSearches"
            tabindex="1"
            aria-label="clear all searches"
            role="button"
            @keydown.enter="clearRecentSearches"
            >Clear</span
          >
        </div>
        <div
          ref="keypressed"
          id="recentearchesDiv1"
          class="item fruit listItemsRecent"
          v-for="(result, index) in recentSearches"
          :key="index"
          @keyup.enter="redirectToResult(result)"
        >
          <div class="recent-itmes-left d-flex gap-3">
            <img  src="../assets/img/clockIconForRecentSearches.svg" role="presentation" alt=" "/>
            <p
              tabindex="1"
              :aria-label="result"
              class="items"
              v-html="highlightMatch(result, input)"
              :title="decodeHTML(result)"
              @click="redirectToResult(result)"
              @keyup.enter="redirectToResult(result)"
            ></p>
          </div>
          <div class="recent-itmes-right">
           <i
              v-if="isMobile"
              class="bi bi-x-lg"
              @click="removeItemFromLocalStorage(index)"
              ></i>
            
              <span v-else @click="removeItemFromLocalStorage(index)" @keydown.enter="removeItemFromLocalStorage(index)" role="button" tabindex="1" aria-label="Click enter to delete this search result, Delete"
              >Delete</span>
            
          </div>
        </div>
      </div>
      <div
        v-if="input.length > 2 && filteredRecentSearches.length > 0 "
        class="recent-searches-list" 
      >
        <div class="recent-search" >
          <span tabindex="1" aria-label="Recent Searches" ref="keypress">Recent Searches</span>
          <span
            @click="clearRecentSearches"
            tabindex="1"
            aria-label="clear all searches"
            role="button"
            @keydown.enter="clearRecentSearches"
            >Clear</span
          >
        </div>
        <div
          ref="keypress"
          id="recentearchesDiv2"
          class="item fruit listItemsRecent"
          v-for="(result, index) in filteredRecentSearches"
          :key="index"
          @keyup.enter="redirectToResult(result)"
        >
          <div class="recent-itmes-left d-flex gap-3">
            <img  src="../assets/img/clockIconForRecentSearches.svg" role="presentation" alt=" "/>
            <p
              tabindex="1"
              :aria-label="result"
              class="items"
              v-html="highlightMatch(result, input)"
              :title="decodeHTML(result)"
              @click="redirectToResult(result)"
              @keyup.enter="redirectToResult(result)"
            ></p>
          </div>
          <div class="recent-itmes-right">
            <i
              v-if="isMobile"
              class="bi bi-x-lg"
              @click="removeItemFromLocalStorage(index)"
              ></i>
            
              <span v-else @click="removeItemFromLocalStorage(index)" @keydown.enter="removeItemFromLocalStorage(index)" role="button" tabindex="1" aria-label="Click enter to delete this search result, Delete"
              >Delete</span>
           
            
          </div>
        </div>
      </div>
      <div
        v-if="input.trim().length >= 3 && filteredList.length > 0"
        class="searchResult"
      >
        <div class="suggest-search" tabindex="1">Suggested searches</div>
        <div
          ref="keypress"
          id="itemsListDiv"
          class="item fruit itemssuggestedDiv"
          v-for="(result, index) in getDisplayList(suggestedSearches)"
          :key="index"
          @click="redirectToResult(result)"
          @keyup.enter="redirectToResult(result)"
          tabindex="1"
          :tabindex="index === 0 ? 0 : -1"
        >
          <img src="../assets/img/searchIconForSuggestedSearches.svg"  role="presentation" alt=" "/>
          <p
            class="itemss"
            v-html="highlightMatch(result, input)"
            :title="decodeHTML(result)"
            :aria-label="result"
          ></p>
        </div>
      </div>
      
      <div
        v-else-if="input.length < 3 && input.length > 0 "
        class="error"
        tabindex="1"
      >
        <p>Enter 3 or more characters to search</p>
      </div>
      <div
        v-else-if="
          input.length >= 3 && suggestedSearches.length === 0 && filteredRecentSearches.length === 0"
        class="error"
        tabindex="1"
      >
        <p>No item matches your search keywords</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DOMPurify from 'dompurify';
import { Encoder } from "../../src/constant/encoder.js"



export default {
  data() {
    return {
      prepositions: ["a", "an", "the", "aboard", "about", "above", "across", "after", "against", "along", "amid", "among", "and", "are", "around", "before", "behind", "below", "beneath", "beside", "besides", "between", "beyond", "but", "concerning", "considering", "despite", "down", "during", "except", "excepting", "excluding", "following", "for", "from", "inside", "into", "like", "minus", "near", "not", "nor", "off", "onto", "opposite", "outside", "over", "past", "per", "plus", "regarding", "round", "save", "since", "than", "the", "there", "through", "toward", "towards", "under", "underneath", "unlike", "until", "upon", "versus", "via", "with", "within", "without", "yet"],
      input: "",
      showResults: false,
      showRecentSearches: false,
      filteredList: [],
      showInput: false,
      isMobile: true,
      focusedIndex: 0,
      noresultFlag : false,
      matchingCount: 0,
      suggestedSearches: [],
      //filteredRecentSearches:[],
    };
  },

  computed: {
    ...mapState(["searchDetails"]),
    getAllItemsList() {
      return Array.isArray(this.searchDetails.NAME)
        ? this.searchDetails.NAME
        : [];
    },
    recentSearches() {
      let courseId = this.$store.state.objID;
      return (
        JSON.parse(localStorage.getItem(courseId + "_searchHistory")) || []
      );
    },
    filteredRecentSearches() {
      // Filter recent searches based on input length and matching words
      let words = this.input.split(" ").filter(x => x !="" && x.length >= 3);
      let flag = false;
      return this.recentSearches.filter(activity => {
        //return this.input.trim().length >= 3 && activity.toLowerCase().includes(this.input.trim().toLowerCase());
        return words.some(inputItems => {
          return activity.toLowerCase().includes(inputItems.trim().toLowerCase());
        })
      });
    },
  },
  watch: {
    recentSearches: {
      handler(newSearches) {
        this.updateRecentSearchesUI();
      },
      deep: true,
    },
    input(newValue) {
      this.showResults = true;
      newValue = newValue.trim();
      if (newValue.split(" ").filter(x => x.length >= 3).length == 0){
        this.noresultFlag = true;
      }else{
        this.noresultFlag = false;
      }
      let searchMatchingList = new Set();
      let allActivityArray = Object.values(this.getAllItemsList)
      if(newValue.length > 2 && !this.noresultFlag){
        var matchedConc = allActivityArray.find(x => Encoder.htmlEncode(x.replace(/ +(?= )/g,'')).toLowerCase() == Encoder.htmlEncode(newValue.trim()).toLowerCase())
        if(matchedConc){
          searchMatchingList.add(matchedConc.replace(/ +(?= )/g,''));
        }
        matchedConc = allActivityArray.filter(x => Encoder.htmlEncode(x.replace(/ +(?= )/g,'')).toLowerCase().includes(Encoder.htmlEncode(newValue.trim()).toLowerCase()) && !this.prepositions.includes(newValue.toLowerCase()))
        if(matchedConc.length > 0){
          for (var i=0;i< matchedConc.length;i++){
              matchedConc[i] = matchedConc[i].replace(/ +(?= )/g,'') 
          }
          searchMatchingList = new Set(Array.from(searchMatchingList).concat(matchedConc))
        }
        var self = this;
        allActivityArray.forEach(function(conceptname){
          var searchWords = newValue.toLowerCase().split(" ").filter(x=>x!="" && x.length >= 3 && !self.prepositions.includes(x.toLowerCase()));
          searchWords.filter( x=> x != "" && x.length >= 3 && !self.prepositions.includes(x.toLowerCase()));
          var flag = false;
          for(var i=0;i<searchWords.length;i++){
            if(conceptname.toLowerCase().search(Encoder.htmlEncode(self.escapeRegExp(searchWords[i]).toLowerCase())) >= 0){
                flag = true;
              }
          }
          if(flag){
            searchMatchingList.add(conceptname.replace(/ +(?= )/g,''))	
          }
          
        });

      }
      this.filteredList = Array.from(searchMatchingList);
      let filteredLists = Object.values(this.filteredList);
       this.matchingCount = this.checkMatchingValues(this.filteredRecentSearches, this.filteredList)
      this.suggestedSearches = this.removeMatchingValues(filteredLists, this.filteredRecentSearches);

      if (this.recentSearches.length === 0 && this.filteredList.length===0 ) {
        $(".allListItems").hide();
        $(".allListItems").css("display", "none");

      } else {
        $(".allListItems").show();
      }
      //const keywords = this.escapeRegExp(newValue).trim().toLowerCase().split(" ");
      if (newValue === "") {
        this.showRecentSearches = true;
        this.filteredList = [];
        this.filteredRecentSearches = [];
        $(".closeButton").hide();
      } else {
        $(".allListItems").show();
        this.showRecentSearches = false;
        if (newValue.trim().length >= 3 ) {
          $(".closeButton").show();
          
        // } else {
        //   this.filteredList = [];
        }
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    handleEnterKey(event) {
      // Check if the Enter key is pressed
      if (event.key === 'Enter') {
        // Focus on the input element
        this.$refs.inputRef.focus();
      }
    },
    sanitizeInput() {
      this.input = DOMPurify.sanitize(this.input);
    },
    decodeHTML(html) {
      var txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },
    escapeHtml(unsafe) {
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
    },
    downArrowClick(){
      //this.$refs.keypress.querySelector('itemsListDiv[0]').focus();
      if ($("#itemsListDiv").is(':visible') && !($("#recentearchesDiv1").is(':visible')) && !($("#recentearchesDiv2").is(':visible')) ) {
        // Focus the first item
        $($("#itemsListDiv")[0]).focus();
      }else if($("#recentearchesDiv1").is(':visible')){
        $($("#recentearchesDiv1")[0]).focus();
      }else if($("#recentearchesDiv2").is(':visible') && $("#itemsListDiv").is(':visible')){
        $($("#itemsListDiv")[0]).focus();
      }
    },
    handleDownArrow() {
      const searchSuggestions = $('.itemss').toArray();
      const recentItem = $('.items').toArray();
      const allSuggestions = recentItem.concat(searchSuggestions);
      if (this.focusedIndex < searchSuggestions.length - 1 && searchSuggestions.length > 0) {
        this.focusedIndex++;
        this.$refs.keypress[this.focusedIndex].focus();
      }else if(this.focusedIndex < recentItem.length - 1 && recentItem.length > 0 && searchSuggestions.length == 0 ){
        this.focusedIndex++;
        this.$refs.keypressed[this.focusedIndex].focus();
      } else {
        this.focusedIndex = 0;
      }
    },
    handleUpArrow() {
      const searchSuggestions = $('.itemss').toArray();
      const recentItem = $('.items').toArray();
      const allSuggestions = recentItem.concat(searchSuggestions);

      if (this.focusedIndex > 0 && searchSuggestions.length > 0) {
        this.focusedIndex--;
        this.$refs.keypress[this.focusedIndex].focus();
      }else if(this.focusedIndex > 0 && recentItem.length > 0 && searchSuggestions.length == 0){
        this.focusedIndex--;
        this.$refs.keypressed[this.focusedIndex].focus();
      }else{
        $($("#input-field")[0]).focus();
      }
    },
    
    focusChild(event) {
      // Focus on the input field when Enter key is pressed
      this.$refs.inputRef.focus(); 
      this.showChildrenonClickEnter;
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent default tab behavior
        this.$refs.inputRef.focus(); // Focus on the input field
        
      }
      this.$refs.inputRef.setAttribute("tabindex", "1");
      this.$refs.inputRef.setAttribute("aria-hidden", "false");
      document.querySelectorAll(".searchInput > *").forEach(function (child) {
          child.tabIndex = 1;
        });
        document
          .querySelector(".searchIconContainer")
          .setAttribute("tabindex", "1");
    },
    escapeRegExp1(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\$&"); // $& means the whole matched string
    },
    updateSearchToLocalStorage(str) {
      let courseId = this.$store.state.objID;
      let searchedHistory =
        JSON.parse(localStorage.getItem(courseId + "_searchHistory")) || [];
      searchedHistory = searchedHistory.filter((item) => item !== str.trim());
      searchedHistory.unshift(str.trim());
      const maxHistoryItems = 5;
      searchedHistory = searchedHistory.slice(0, maxHistoryItems);
      localStorage.setItem(
        courseId + "_searchHistory",
        JSON.stringify(searchedHistory)
      );
    },
    removeItemFromLocalStorage(index) {
  let courseId = this.$store.state.objID;


  this.recentSearches.splice(index, 1);
  localStorage.setItem(
    courseId + "_searchHistory",
    JSON.stringify(this.recentSearches)
  );


  this.recentSearches = [...this.recentSearches];  
  this.updateRecentSearchesUI();  

  
  this.$nextTick(() => {
    const allListItems = document.querySelector('.allListItems');
    if (allListItems) {
      allListItems.style.display = 'none'; 
    }


    const searchInput = document.querySelector('#searchInput');  
    if (searchInput) {
      searchInput.value = '';  
    }

    
    const nextFocusableElement = document.querySelector('#nextFocusElement'); 
    if (nextFocusableElement) {
      nextFocusableElement.focus(); 
    } else {
      document.activeElement.blur();  
    }

    
    window.scrollTo(0, 0);
  });

  
  console.log('Item removed from recentSearches:', this.recentSearches);
  console.log('LocalStorage updated:', localStorage.getItem(courseId + "_searchHistory"));
},

    clearRecentSearches() {
      $(".allListItems").hide();
      let courseId = this.$store.state.objID;
      this.recentSearches.splice(0, this.recentSearches.length);
      localStorage.setItem(
        courseId + "_searchHistory",
        JSON.stringify(this.recentSearches)
      );
      this.recentSearches = [...this.recentSearches];
      this.updateRecentSearchesUI();
    },
    updateRecentSearchesUI() {
      const recentSearchesContainer = document.querySelector(
        ".recent-searches-list"
      );
      recentSearchesContainer.innerHTML = "";
      this.recentSearches.forEach((search, index) => {
        const searchItem = document.createElement("div");
        searchItem.className = "item fruit listItemsRecent";
        searchItem.innerHTML = `
                <div class="recent-itmes-left d-flex gap-3">
                    <i class="bi bi-clock clocks"></i>
                    <p tabindex="1" aria-label="${search}" class="items">${this.highlightMatch(
          search,
          this.input
        )}</p>
                </div>
                <div class="recent-itmes-right">
                    <i v-if="${this.isMobile}" class="bi bi-x-lg" @click="removeItemFromLocalStorage(${index})"></i>
                    <span v-else @click="removeItemFromLocalStorage(${index})" tabindex="1">Delete</span>
                </div>
            `;
        recentSearchesContainer.appendChild(searchItem);
      });
    },
    checkMatchingValues(array1, array2) {
      let count = 0;
      for (let value of array1) {
          if (array2.includes(value)) {
              count++;
          }
      }
      return count;
    },
    removeMatchingValues(arr1, arr2) {
        let result = arr1.slice();
        arr2.forEach(function(value) {
            let index = result.indexOf(value);
            if (index !== -1) {
                result.splice(index, 1);
            }
        });
        return result;
    },
    getDisplayList(list) {
      if (this.recentSearches.length > 0) {
        return this.isMobile
          ? list.slice(0, 8 - this.matchingCount)
          : list.slice(0, 10 - this.matchingCount);
      }
      return this.isMobile ? list.slice(0, 8) : list.slice(0, 10);
    },
    getIndicesOf(searchStr, str, caseSensitive) {
        var searchStrLen = searchStr.length;
        if (searchStrLen == 0) {
            return [];
        }
        var startIndex = 0, index, indices = [];
        if (!caseSensitive) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        return indices;
    },
    // Method to highlight the matched part of the string
    highlightMatch(text, query) {
        let decodedText = DOMPurify.sanitize(text); 
        let decodedQuery = DOMPurify.sanitize(query); 
        let words = decodedQuery.toLowerCase().split(" ").filter((word) => word.length > 2  );
        let exactMatch = Encoder.htmlEncode(decodedText);
        if ( exactMatch.replace(/ +(?= )/g,'').toLowerCase() == Encoder.htmlEncode(decodedQuery.replace(/ +(?= )/g,'')).toLowerCase()){
					exactMatch = '<b>'+exactMatch+'</b>';
				}else{
          decodedQuery = Encoder.htmlEncode(decodedQuery);
          if (decodedQuery.split(" ").filter(x => x.length >= 3).length > 1 && exactMatch.toLowerCase().includes(decodedQuery.toLowerCase())){
						var arrAllIndices = this.getIndicesOf(decodedQuery.toLowerCase().replace(/\s{2,}/g, ' '), exactMatch, false)
						var len = decodedQuery.toLowerCase().replace(/\s{2,}/g, ' ').length;
						for (var j= 0; j< arrAllIndices.length;j++){
							exactMatch = exactMatch.replace(exactMatch.substring(arrAllIndices[j],arrAllIndices[j] + len),'<b>'+exactMatch.substring(arrAllIndices[j],arrAllIndices[j] + len)+'</b>');
 							arrAllIndices = this.getIndicesOf(decodedQuery.toLowerCase().replace(/\s{2,}/g, ' '), exactMatch, false);
 						}
						
					} else {
						var wordsList = decodedQuery.split(" ").filter(x=>x!="" && x.length >= 3 && !this.prepositions.includes(x.toLowerCase()));
						 for (var j=0;j<wordsList.length;j++){
							 if (j==0 || !"</b>".includes(wordsList[j]))
							 	exactMatch = exactMatch.replace(new RegExp(Encoder.htmlEncode(this.escapeRegExp(wordsList[j])), 'gi'), (match) => '<b>'+match+'</b>');
						 }	
					}
        }

    //     if (!words.length) return decodedText;

    //     let highlightedText = decodedText;

    //     words.forEach((word) => {
    //     const regex = new RegExp(word.replace(/([\\.\\+?*^(){}|[\]\\]|—)/g, "\\$&"), "gi");
    //     highlightedText = highlightedText.replace(
    //         regex,
    //         (match) => {
    //             if (match.includes("&") && !match.includes("&mdash;") && match !== "&" ) {
    //                 // Convert HTML entities back to special characters
    //                 return match.replace(/&amp;/g, "&").replace(/&lt;/g, "<");
    //             } else {
    //                 // Wrap the match in <b> tags to make it bold
    //                 return `<b>${match}</b>`;
    //             }
    //         }
    //     );
    // });
    const decodedHighlightedText = DOMPurify.sanitize(exactMatch);
    return decodedHighlightedText;
    },
    clearSearchBox() {
      this.input = "";
      $(".allListItems,.searchResult,.recent-searches-list, .error").hide();
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth < 768;
    },
    ...mapActions(["fetchSearchDetails"]),
      showChildrenonClickEnter() {
          var parent = document.getElementById("searchInput");
          var children = parent.querySelectorAll("*[aria-hidden='true']");
          children.forEach(function(child) {
              child.setAttribute("aria-hidden", "false");
              child.setAttribute("tabindex", "1");
              child.setAttribute("tival", "1");
          });
    },
    handleEnterKeyFocus(event) {
      if (event.keyCode === 13) {
        this.handleFocus;
        document.querySelectorAll(".searchInput > *").forEach(function (child) {
          child.tabIndex = 1;
        });
        
        document
          .querySelector(".searchIconContainer")
          .setAttribute("tabindex", "1");
      }
      if (event.key === "Enter") {
        this.showResults = true;
        if (this.input.length > 0) {
          this.showRecentSearches = false;
          $(".closeButton").show();
        } else {
          this.showRecentSearches = true;
          $(".closeButton").hide();
        }
      }
    },
    handleFocus() {
      if (this.recentSearches.length === 0 && this.filteredList.length===0) {
        this.showResults = false;
        $(".allListItems").hide();
        $(".allListItems").css("border","none");
      } else {
        this.showResults = true;
        $(".allListItems").show();
        $(".allListItems").css("border","1px solid #9E9E9E");
      }
      document.querySelectorAll(".searchInput > *").forEach(function (child) {
          child.tabIndex = 1;
      });
      $(".searchIconContainer").attr("aria-hidden", "false")
      if (this.input.length > 0) {
        this.showRecentSearches = false;
        $(".closeButton").show();
      } else {
        if (this.input.length > 0) {
          this.showRecentSearches = false;
        } else {
          this.showRecentSearches = true;
        }
        $(".closeButton").hide();
      }
      
    },
    handleBlur() {
      this.showResults = false;
      this.showRecentSearches = false;
      $(".allListItems").hide();
    },

    redirectToResult(searchTerm) {
      var cid = this.$store.state.objID;
      if (
        searchTerm.trim() !== "" &&
        searchTerm.trim().length > 2 &&
        $("#input-field").is(":visible")
        && $(".allListItems").is(":visible")
      ) {
        window.location.href =
          "https://qa4.cogbooks.com/communicog/handler?action=topicsearchpage&COURSEID=" +
          cid +
          "&SEARCHKEY=" +
          encodeURIComponent(searchTerm);
        //this.addToRecentSearches(searchTerm);
        this.updateSearchToLocalStorage(searchTerm);
      }
      if (this.isMobile) {
        $("#input-field").show();
        $("#input-field").focus();
        $(".searchInput").css({ border: "1px solid #9E9E9E" });
        $("#header-div").css("justify-content", "center");
        $("#header-div").removeClass("justify-content-between");
        $(".profile-icon").hide();
        $(".sidebar-nav-toggle-icon").hide();
        $(".searchIconContainer").css("border-left", "1px solid #9E9E9E");
        $(
          ".searchInput, .allListItems,.searchResult,.recent-searches-list, .error"
        ).css({
          width: "90vw",
        });
        this.showRecentSearches = true;
      } else {
        $("#input-field").show();
        $(".profile-icon").show();
        $(".sidebar-nav-toggle-icon").show();
      }
    },

  },
  mounted() {
    this.activeIndex = this.recentSearches.length > 0 ? 0 : null;
    if (!$("#input-field").is(":visible")) {
      $("#input-field").css("pointer-events", "none");
    } else {
      $("#input-field").css("pointer-events", "all");
    }
    $(".closeButton").hide();
    document.addEventListener("click", (event) => {
      if (!this.$el.contains(event.target)) {
        this.showResults = false;
        $(".closeButton").hide();
        if (this.isMobile) {
          $(".searchIconContainer").css("border-left", "none");
          $("#input-field").css("pointer-events", "none");
          $(".closeButton").hide();
          $(".profile-icon").show();
          $(".searchInput").css({"border": "none"});
          $(".sidebar-nav-toggle-icon").show();
          $(".allListItems").hide();
          $("#input-field").hide();
          $(".searchInput").css({"width": "330px"});
        }
      } else {
        
        this.showResults = true;
        if(this.recentSearches.length === 0){
          this.showResults = false;
        }
        $("#input-field").css("pointer-events", "all");
        $(".searchIconContainer").css("border-left", "1px solid #9E9E9E");
      }
    });
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    if (this.isMobile) {
      $("#input-field").hide();
      $(".searchIconContainer").css("border-left", "none");
      $(".searchInput").css({ border: "none", "justify-content": "end" });
      $(".searchInput, .searchResult,.recent-searches-list, .error").css({
        width: "300px",
      });
    }
    var objid = this.$store.state.objID;
    this.fetchSearchDetails({ objID: objid });
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

input {
  display: block;
  width: 350px;
  height: 100%;
  font: normal normal normal 16px/32px "Source Sans 3";
  color: #424242;
  border: none;
}
input:focus {
  outline: none;
}
.listItemsRecent {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
}
.recent-itmes-right {
  color: #4756d3;
  cursor: pointer;
}
.searchInput {
  align-items: center;
  display: flex;
  width: 375px;
  height: 44px;
  padding: 0 0 0 16px;
  border: 1px solid #9e9e9e;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
}
.bi-x-lg {
  color: #212121;
  -webkit-text-stroke: 1px !important;
}
.searcIconImg {
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.searchIconContainer {
  height: 100%;
  align-items: center;
  padding: 0 16px;
  display: flex;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #9e9e9e;
}
.searchIconImg{
  margin-inline: 5px;
}


.searchInput:hover {
  border: 1px solid #212121;
}
.searchInput:hover .searchIconContainer {
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-left: 1px solid #212121;
}
.clocks {
  color: #9e9e9e !important;
  height: 16px;
  width: 16px;
  font-size: smaller;
  -webkit-text-stroke: 1px;
}
.closeButton:focus {
  outline: #212121;
}
.suggest-search,
.recent-search {
  font: normal normal 600 16px/22px "Source Sans 3";
  letter-spacing: 0px;
  color: #212121;
  padding-inline: 17px;
  padding-top: 16px;
  padding-bottom: 5px;
}
.suggest-search{
  padding-bottom: 10px;
}

.recent-search {
  display: flex;
  justify-content: space-between;
}
.recent-search span:last-child {
  color: #5465f8;
  cursor: pointer;
}
.is-active {
  background-color: #dedede;
}
.allListItems {
  background-color: #ffffff;
  width: 375px;
  max-height: 320px;
  overflow-y: auto;
  border-radius: 4px;
  display: block;
  position: absolute;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  margin-top: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.recent-search {
  font: normal normal 600 16px/22px "Source Sans 3";
  letter-spacing: 0px;
  color: #212121;
}
#closeButton {
  height: 36px;
  width: 36px;
  padding: 0 10px;
}
#closeButton:hover {
  background-color: #f3f3f3;
  border-radius: 50%;
}
#closeButton:focus {
  outline: 2px solid #212121 !important;
}
.item {
  font: normal normal normal 16px/44px "Source Sans 3";
  letter-spacing: 0px;
  color: #212121;
  display: flex;
  gap: 10px;
  align-items: baseline;
  padding: 0 17px;
}
.item:hover {
  background-color: #eef0fe;
}
.items {
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
}
.itemss {
  max-width: 360px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.p {
  line-height: 1;
}
.fruit {
  background-color: #ffffff;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#itemsListDiv{
  display: flex;
  align-items: center;
}

.error {
  background-color: #ffffff;
  font: normal normal 600 16px/22px "Source Sans 3";
  width: 373px;
  max-height: 300px;
  padding: 20px 17px;
}
p {
  margin: 0 !important;
}
.closeButton {
  color: #212121;
  border-radius: 50%;
  outline: none !important;
}
.btn:active,
.closeButton:active {
  border: none !important;
  outline: none !important;
}
.closeButton:active {
  box-shadow: none !important;
  outline: none !important;
}
.btn:focus {
  box-shadow: none !important;
}
.listItemsRecent{
  gap: 0px !important;
}
@media only screen and (max-width: 1024px) {
  .searchInputExpanded {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #9e9e9e;
    border-radius: 5px;
    background: #ffffff;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .searchInputExpanded input {
    flex: 1;
    border: none;
    outline: none;
    font: normal normal normal 16px/32px "Source Sans 3";
    color: #424242;
  }
  .searchIconContainer:hover{
    border-radius: none;
    background: #ffffff !important;
  }
  .searchIconContainer {
    padding: 15px;
    cursor: pointer;
  }
  .searchInput {
    width: 300px;
  }
  .error,
  .searchResult,
  .recent-searches-list,
  .allListItems {
    width: 90vw;
  }
  .error{
    font: normal normal 600 16px/28px "Source Sans 3";
  }
}
</style>
